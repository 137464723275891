import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Http from "../../components/infrastructures/Http";
import PageLoad from "../../components/views/PageLoad";
import { Button, Typography } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import Certificates from "../../components/views/Certificates";
import { RequestListCouponsByGroupInvoiceUUID, RequestListInvoicesByGroupInvoiceUUID, RequestPrintCouponsByGroupInvoiceUUID, RequestDownload } from '../../components/infrastructures/UrlEndpoints';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import { numberToMoney } from "../../components/views/Functions";
import AlertModal from "../../components/views/AlertModal";

const ListCertificatesAndInvoicesToImpress = () => {

    let navigate = useNavigate();

    const { hash } = useParams();

    const [loading, setLoading] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [groupInvoiceUUID, setGroupInvoiceUUID] = useState(null);
    const [open, setOpen] = useState(false);
    const [message] = useState([
        "Fallha ao processar arquivo PDF para impressão",
        "Entre em contato com o administrador"
    ]);

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            try {
                let _params = JSON.parse(atob(hash));
                await getListCouponsByGroupInvoiceUUID(_params.GroupInvoiceUuid);
                await getListInvoicesByGroupInvoiceUUID(_params.GroupInvoiceUuid);
                await setGroupInvoiceUUID(_params.GroupInvoiceUuid);
            } catch (error) {
                alert("Falha inesperada. Entre em contato com o adminstrador.");
            }
            _setLoading(false);
        }

        processResquetAPI()
    }, [hash]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getListCouponsByGroupInvoiceUUID = async (uuid) => {
        Http({
            method: "GET",
            url: RequestListCouponsByGroupInvoiceUUID(uuid)
        }).then(response => {
            setCoupons(response.data);
        }).catch(err => {
            alert("Falha inesperada. Entre em contato com o adminstrador.");
            setCoupons([])
        });
    }

    const getListInvoicesByGroupInvoiceUUID = async (uuid) => {
        Http({
            method: "GET",
            url: RequestListInvoicesByGroupInvoiceUUID(uuid)
        }).then(response => {
            const invs = response.data.map(item => {
                item.Value = numberToMoney(item.Value);
                return item;
            })
            setInvoices(invs);
        }).catch(err => {
            alert("Falha inesperada. Entre em contato com o adminstrador.");
            setInvoices([])
        });
    }

    const handlePrintCertificates = (event) => {
        event.preventDefault();
        createPdf();
    }

    const createPdf = () => {
        _setLoading(true);
        Http({
            method: "GET",
            url: RequestPrintCouponsByGroupInvoiceUUID(groupInvoiceUUID)
        }).then(response => {
            _setLoading(false);
            download(response.data);
        }).catch(err => {
            _setLoading(false);
            setOpen(true);
        });
    }

    const download = (file) => {
        _setLoading(true);
        Http({
            method: "GET",
            url: RequestDownload(file),
            responseType: 'blob'
        }).then(response => {
            _setLoading(false);
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('download', 'file.pdf');
            link.href = url;
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            _setLoading(false);
            setOpen(true);
        });
    }

    const handleClose = (event) => {
        setOpen(false);
    }

    const columns = [
        {
            id: 'Document',
            label: 'Documento',
            align: 'left'
        },
        {
            id: 'Value',
            label: 'Valor',
            align: 'right'
        },
        {
            id: 'DescriptionType',
            label: 'Tipo',
            align: 'left'
        },
        {
            id: 'Place',
            label: 'Lugar',
            align: 'left'
        }
    ];

    return (
        <div style={{width: "800px", margin: "auto"}}>

            <PageLoad open={loading} key={keyLoading} />

            <AlertModal
                open={open}
                handleClose={handleClose}
                message={message}
                title="Atenção"
                key={open}
            />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Dados para reimpressão de certificados</Typography>
            </div>

            <br />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Certificados</Typography>
            </div>

            {(coupons.length !== 0) && (
                <div style={{width: "400px", display: "grid", gridGap: "10px", gridTemplateColumns: "repeat(4, 100px)"}}>

                    {coupons.map((item, index) => {
                        return <Certificates coupon={item.Certificate} key={index} />
                    })}
                </div>
            )}

            <br />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Notas fiscais</Typography>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={invoices}/>
            </div>

            <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handlePrintCertificates}>
                    Reimprimir
                </Button>
            </div>
        </div>
    )
}

export default ListCertificatesAndInvoicesToImpress;