import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Button, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../components/views/Theme';
import successIcon from "../../images/success-icon.png"
import AlertModal from "../../components/views/AlertModal";
import WarningIcon from '@material-ui/icons/Warning';
import { RequestUserChangePasswordUUID } from "../../components/infrastructures/UrlEndpoints";

const AlterPasswordUser = (props) => {

    let navigate = useNavigate();

    const { hash } = useParams();
    const [uuid, setUUID] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const [error, setError] = useState({
        password: {validate: true, message: null},
        confirm: {validate: true, message: null},
    });

    useEffect(() => {
        async function processResquetAPI() {
            try {
                let _params = JSON.parse(atob(hash));
                setUUID(_params.UUID);
                setName(_params.Name);
            } catch (error) {
                navigate("/sistema");
            }
        }

        processResquetAPI()
    }, [hash])

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());

    const [modalSuccess, setModalSuccess] = useState(false);
    const [keyModalSuccess, setKeyModalSuccess] = useState(Math.random());

    const [modalError, setModalError] = useState(false);
    const [keyModalError, setKeyModalError] = useState(Math.random());

    const [message, setMessage] = useState([]);

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (password === "") {
            _error.password.validate = false;
            _error.password.message = "Por favor, insira sua senha. Campo senha é obrigatório.";
            _verify = true;
        } else if (password.length < 6) {
            _error.password.validate = false;
            _error.password.message = "Senha deve ter mais de 6 digitos.";
            _verify = true;
        }

        if (confirm === "") {
            _error.confirm.validate = false;
            _error.confirm.message = "Por favor, insira confirmação de senha. Campo confirmação é obrigatório.";
            _verify = true;
        } else if (password !== confirm) {
            _error.confirm.validate = false;
            _error.confirm.message = "Senhas são diferentes.";
            _verify = true;
        }

        setError(_error);
        return _verify;
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        _setLoading(true);

        Http({
            method: "PUT",
            url: RequestUserChangePasswordUUID(uuid),
            data: {
                Password: password,
                Confirm: confirm
            }
        }).then(response => {
            _setLoading(false);
            _setMessageSuccess(true);
        }).catch(err => {
            _setLoading(false);
            let message = ["Falha inesperada. Entre em contato com o Administrador."];
            if (typeof err.response !== "undefined" && Array.isArray(err.response.data)) {
                let _error = {...error};
                err.response.data.map(item => {
                    _error[item.Field.toLowerCase()] = {
                        validate: false,
                        message: item.Message
                    }
                });
                setError(_error);

                message = [];
                message.push("Não foi possível atualizar as informações.");
                message.push("Verifique e corriga a informação.");
                message.push("Ao final reenvie os dados.");
            }
            setMessage(message);
            _setMessageError(true);
        });
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const _setMessageSuccess = (option) => {
        setModalSuccess(option);
        setKeyModalSuccess(Math.random());
    }

    const _setMessageError = (option) => {
        setModalError(option);
        setKeyModalError(Math.random());
    }

    const handleOk = (event) => {
        event.preventDefault();
        _setMessageSuccess(false);
        _setMessageError(false);
    }

    const getSuccessMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <img src={successIcon} className="img-success-icon mb20" alt="Icone sucesso" />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Senha atualizados com sucesso!</p>
                </div>

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    const getErrorMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                {message.map((item, index) => {
                    return (
                        <div key={index}>
                            <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>{item}</p>
                        </div>
                    )
                })}

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div style={{width: "400px", margin: "auto"}}>
            <form>
                <PageLoad open={loading} key={keyLoading} />

                <AlertModal
                    open={modalSuccess}
                    handleClose={_setMessageSuccess}
                    message={null}
                    title={null}
                    body={getSuccessMessage()}
                    key={keyModalSuccess}
                />

                <AlertModal
                    open={modalError}
                    handleClose={_setMessageError}
                    message={null}
                    title={null}
                    body={getErrorMessage()}
                    key={keyModalError}
                />

                <div style={{margin:"auto", paddingTop: "20px", paddingBottom: "20px"}}>
                    <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Alterar senha do usuário</Typography>
                </div>

                <ThemeProvider theme={Theme}>
                    <TextField
                        value={name}
                        color="primary"
                        id="name"
                        name="name"
                        label="Nome"
                        size="small"
                        margin="dense"
                        type="text"
                        disabled
                        fullWidth
                    />

                    <TextField
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onFocus={(event) => {
                            let _error = {...error};
                            _error.password.validate = true;
                            _error.password.message = "";
                            setError(_error);
                        }}
                        error={!error.password.validate}
                        helperText={error.password.message}
                        color="primary"
                        id="password"
                        name="password"
                        label="Senha"
                        size="small"
                        margin="dense"
                        type="password"
                        placeholder="Digite sua senha"
                        fullWidth
                    />

                    <TextField
                        value={confirm}
                        onChange={(event) => setConfirm(event.target.value)}
                        onFocus={(event) => {
                            let _error = {...error};
                            _error.confirm.validate = true;
                            _error.confirm.message = "";
                            setError(_error);
                        }}
                        error={!error.confirm.validate}
                        helperText={error.confirm.message}
                        color="primary"
                        id="confirm"
                        name="confirm"
                        label="Confirmar"
                        size="small"
                        margin="dense"
                        type="password"
                        placeholder="Digite confirmação da senha"
                        fullWidth
                    />

                </ThemeProvider>

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handleSubmitForm}>
                        Salvar
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AlterPasswordUser;