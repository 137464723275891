import React from 'react';
import Theme from './Theme';

const AbaRegisterInvoices = (props) => {
    let bg = (props.active) ? "#ffffff" : "transparent";
    return (
        <span style={{backgroundColor: bg, ...Theme.palette.btnAbaRegisterInvoice}}>
            <a onClick={(event) => props.setVisible(event)}>{props.title}</a>
        </span>
    )
}

export default AbaRegisterInvoices;