import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "../../views/public/Login";
import NotFound from "../../views/public/NotFound";
import Main from "../../views/system/Main";
import Dashboard from "../../views/system/Dashboard";
import Users from "../../views/system/Users";
import User from "../../views/system/User";
import TaxPayer from "../../views/system/TaxPayer";
import TaxPayers from "../../views/system/TaxPayers";
import TaxPayersInvoices from "../../views/system/TaxPayersInvoices";
import TaxPayersApplicationLogin from "../../views/system/TaxPayersApplicationLogin";
import AlterPasswordUser from "../../views/system/AlterPasswordUser";
import AlterPasswordTaxPayer from "../../views/system/AlterPasswordTaxPayer";
import RegisterInvoices from "../../views/system/RegisterInvoices";
import ListInvoicesInAnalysis from "../../views/system/ListInvoicesInAnalysis";
import InvoiceInAnalysis from "../../views/system/InvoiceInAnalysis";
import RegisterAward from "../../views/system/RegisterAward";
import ListAwards from "../../views/system/ListAwards";
import ListInvoicesTaxPayer from "../../views/system/ListInvoicesTaxPayer";
import Prize from "../../views/system/Prize";
import CancelCoupons from "../../views/system/CancelCoupons";
import ListCertificatesAndInvoicesToCancel from "../../views/system/ListCertificatesAndInvoicesToCancel";
import ListCertificatesAndInvoicesToImpress from "../../views/system/ListCertificatesAndInvoicesToImpress";
import ReprocessingInvoices from "../../views/system/ReprocessingInvoices";
import ListCertificates from "../../views/system/ListCertificates";
import { isAuthenticated } from "../../components/infrastructures/Auth";

function PrivateRoute({ children }) {
    const auth = isAuthenticated();
    return auth ? children : <Navigate to="/login" />;
}

const Router = () => (
    <BrowserRouter>
        <Routes>

            <Route path="/" element={<Login />} exact />

            <Route
                path="/sistema"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu"]} title={"Listar notas"}>
                            <Dashboard />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/usuarios"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Listar usuários"]} title={"Listar usuários"}>
                            <Users />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/usuario"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Listar usuários"]} title={"Listar usuários"}>
                            <User />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/usuario/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Listar usuários"]} title={"Listar usuários"}>
                            <User />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/alterar-senha/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Alterar senha"]} title={"Alterar senha"}>
                            <AlterPasswordUser />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/contribuinte"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Contribuinte"]} title={"Cadastrar contribuinte"}>
                            <TaxPayer />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/contribuinte/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Contribuinte"]} title={"Cadastrar contribuinte"}>
                            <TaxPayer />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/contribuintes"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Listar contribuinte"]} title={"Listar cadastrar contribuinte"}>
                            <TaxPayers />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/contribuintes-notas-enviadas"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Listar contribuinte"]} title={"Listar cadastrar contribuinte"}>
                            <TaxPayersApplicationLogin />
                        </Main>
                    </PrivateRoute>
                }
            />


            <Route
                path="/notas-contribuintes"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Listar contribuinte"]} title={"Listar cadastrar contribuinte"}>
                            <TaxPayersInvoices />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/lista-invoices-contribuintes/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Listar contribuinte"]} title={"Listar cadastrar contribuinte"}>
                            <ListInvoicesTaxPayer />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/alterar-senha-contribuinte/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Menu", "Listar contribuintes", "Alterar senha do contribuinte"]} title={"Alterar senha do contribuinte"}>
                            <AlterPasswordTaxPayer />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/notas"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cadastrar notas"]} title={"Cadastrar notas"}>
                            <RegisterInvoices />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="/notas/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cadastrar notas"]} title={"Cadastrar notas"}>
                            <RegisterInvoices />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="notas-em-analise"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cadastrar notas"]} title={"Cadastrar notas"}>
                            <ListInvoicesInAnalysis />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="nota-em-analise/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cadastrar notas"]} title={"Cadastrar notas"}>
                            <InvoiceInAnalysis />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="cadastrar-sorteio"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cadastrar sorteio"]} title={"Cadastrar sorteio"}>
                            <RegisterAward />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="listar-sorteios"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cadastrar sorteio"]} title={"Cadastrar sorteio"}>
                            <ListAwards />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="sortear"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cadastrar notas"]} title={"Cadastrar notas"}>
                            <Prize />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="sortear/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cadastrar notas"]} title={"Cadastrar notas"}>
                            <Prize />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="cancelar-certificados/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Cancelar certificados"]} title={"Cancelar certificados"}>
                            <CancelCoupons />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="lista-certificados-notas-cancelar/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Lista de certificados e notas para cancelar"]} title={"Lista de certificados e notas para cancelar"}>
                            <ListCertificatesAndInvoicesToCancel />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="lista-certificados-reimpressao/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Lista de certificados e notas para cancelar"]} title={"Lista de certificados e notas para cancelar"}>
                            <ListCertificatesAndInvoicesToImpress />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="reprocessar/:hash"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Reprocessar certificados e notas"]} title={"Reprocessar certificados e notas"}>
                            <ReprocessingInvoices />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route
                path="listar-certificados"
                element={
                    <PrivateRoute>
                        <Main breadcrumbs={["Home", "Listar certificados"]} title={"Listar certificados"}>
                            <ListCertificates />
                        </Main>
                    </PrivateRoute>
                }
            />

            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);

export default Router;