import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Typography } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { RequestAwards } from "../../components/infrastructures/UrlEndpoints";

const ListAwards = () => {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [list, setList] = useState([]);

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getPrizes();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getAwards = (items) => {
        return <div>
            {items.map(item => {
                return (
                    <div>{item.Description}</div>
                )
            })}
        </div>
    }

    const getPrizes = async () => {
        await Http({
            method: "GET",
            url: RequestAwards(),
        }).then(response => {
            let _list = response.data.map(item => {
                return {
                    Option: getButtons(item),
                    Date: item.DateAward,
                    Awards: getAwards(item.Awards)
                }
            })
            setList(_list);
        }).catch(err => {
            setList([]);
        });
    }

    // const handleDelete = (event, id) => {
    //     console.log("id", id);
    // }

    const handlePrize = (event, item) => {
        event.preventDefault();
        let hash = btoa(JSON.stringify({
            Date: item.Date
        }));
        navigate(`/sortear/${hash}`);
    }

    const getButtons = (item) => {
        return (
            <>
                {/* <IconButton aria-label="delete" onClick={(event) => handleDelete(event, item)}>
                    <DeleteIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton> */}
                <IconButton aria-label="delete" onClick={(event) => handlePrize(event, item)}>
                    <CardGiftcardIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
            </>
        );
    }

    const columns = [
        {
            id: 'Option',
            label: 'Opções'
        },
        {
            id: 'Date',
            label: 'Data',
            align: 'left'
        },
        {
            id: 'Awards',
            label: 'Prêmios',
            align: 'left'
        }
    ];

    return (
        <div style={{width: "800px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Sorteios</Typography>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={list} />
            </div>
        </div>
    );
};

export default ListAwards;