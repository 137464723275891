import React, { useState, useEffect } from "react";
import { Typography } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import StepOne from "./register-invoices-steps/StepOne";
import StepTwo from "./register-invoices-steps/StepTwo";
import StepThree from "./register-invoices-steps/StepThree";
import StepFour from "./register-invoices-steps/StepFour";
import Theme from '../../components/views/Theme';
import { numberToMoney } from "../../components/views/Functions";
import { RequestRegisterInvoices, RequestListInvoicesByGroupInvoiceUUID, RequestTaxPayerByGroupInvoiceUUID } from "../../components/infrastructures/UrlEndpoints";

const ReprocessingInvoices = () => {

    let navigate = useNavigate();
    const { hash } = useParams();
    const [data, setData] = useState({});
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [keyData, setKeyData] = useState(Math.random());
    const [groupInvoiceUUID, setGroupInvoiceUUID] = useState(null);

    useEffect(() => {
        setKeyData(Math.random());
    },[data]);

    useEffect(() => {
        async function processResquetAPI() {
            try {
                const invoices = await getListInvoicesByGroupInvoiceUUID(hash);
                const taxPayer = await getTaxPayerByGroupInvoiceUUID(hash);
                let _data = {...data};
                _data.Name = taxPayer.Name;
                _data.UUID = taxPayer.UUID;
                _data.Invoices = invoices;
                _data.GroupInvoiceUUID = hash;
                setData(_data);
            } catch (error) {
                alert("Falha inesperada. Entre em contato com o adminstrador.");
                navigate(`/`);
            }
        }

        processResquetAPI()
    }, [hash])

    const getListInvoicesByGroupInvoiceUUID = async (uuid) => {
        let invoices = [];
        await Http({
            method: "GET",
            url: RequestListInvoicesByGroupInvoiceUUID(uuid)
        }).then(response => {
            invoices = response.data.map(item => {
                item.Value = numberToMoney(item.Value);
                return item;
            })
        }).catch(err => {
            alert("Falha inesperada. Entre em contato com o adminstrador.");
        });
        return invoices;
    }

    const getTaxPayerByGroupInvoiceUUID = async (uuid) => {
        let taxPayer = {};
        await Http({
            method: "GET",
            url: RequestTaxPayerByGroupInvoiceUUID(uuid)
        }).then(response => {
            taxPayer.UUID = response.data.UUID;
            taxPayer.Name = response.data.Name;
        }).catch(err => {
            alert("Falha inesperada. Entre em contato com o adminstrador.");
            taxPayer.UUID = null;
            taxPayer.Name = null;
        });
        return taxPayer;
    }

    const send = async (taxPayerUUID, invoices) => {
        _setLoading(true);

        let rs = await Http({
            method: "POST",
            url: RequestRegisterInvoices(),
            data: {
                UUID: taxPayerUUID,
                Invoices: invoices
            }
        }).then(response => {
            _setLoading(false);
            setGroupInvoiceUUID(response.data.UUID);
            return {
                "status": true,
                "message" : null,
            }
        }).catch(err => {
            _setLoading(false);
            let errors = [];
            if (err.response.status == 400) {
                if (Array.isArray(err.response.data.message)) {
                    err.response.data.message.map(item => {
                        if (item.field == "Message") {
                            errors.push(item.description.join());
                        }
                    });
                }
            }

            if (errors.length == 0) {
                errors.push("Erro inesperado. Entre em contato com o adminstrador. [ERI.003]");
            }

            return {
                "status": false,
                "message" : errors,
            }
        });

        return rs;
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const setPreviewData = (info) => {
        let _data = {...data, ...info};
        setData(_data);
    }

    const resetInformations = () => {
        setData({});
        setGroupInvoiceUUID(null);
        setKeyLoading(Math.random());
        setStep(0)
    }


    const steps = [
        <StepThree
            setStep={setStep}
            send={send}
            keyData={keyData}
            data={data}
            width="800px"/>,

        <StepFour
            setStep={setStep}
            groupInvoiceUUID={groupInvoiceUUID}
            resetInformations={resetInformations}
            width="400px"/>
    ];

    return (
        <div>
            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px", paddingBottom: "20px", width: "800px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Cadastrar notas</Typography>
            </div>

            <div>
                {steps[step]}
            </div>
        </div>
    );
};

export default ReprocessingInvoices;