import React from "react";
import ReactDOM from "react-dom";
import DateFnsUtils from '@date-io/date-fns';
import "./index.css";
import "./media.css";
import "./drawer.css";
import Router from "./components/infrastructures/Router";
import reportWebVitals from "./reportWebVitals";
import { ApplicationContextProvider } from "./components/infrastructures/Context";
import { initialState, changeUserNameReducer } from "./components/infrastructures/Reducer";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

ReactDOM.render(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ApplicationContextProvider initialState={initialState} reducer={changeUserNameReducer}>
            <Router />
        </ApplicationContextProvider>
    </MuiPickersUtilsProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
