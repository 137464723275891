import React, { useState, useEffect } from "react";
import { Typography, Button } from '@material-ui/core';
import { useParams, useNavigate } from "react-router-dom";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import StepOne from "./invoice-in-analysis/StepOne";
import StepFour from "./register-invoices-steps/StepFour";
import Theme from '../../components/views/Theme';
import successIcon from "../../images/success-icon.png"
import AlertModal from "../../components/views/AlertModal";
import WarningIcon from '@material-ui/icons/Warning';
import { RequestAvailableInvoice } from "../../components/infrastructures/UrlEndpoints";

const InvoiceInAnalysis = () => {

    let navigate = useNavigate();

    const { hash } = useParams();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());

    const [messageAlert, setMessageAlert] = useState("");

    const [modalSuccess, setModalSuccess] = useState(false);
    const [keyModalSuccess, setKeyModalSuccess] = useState(Math.random());

    const [modalError, setModalError] = useState(false);
    const [keyModalError, setKeyModalError] = useState(Math.random());

    const [message, setMessage] = useState([]);

    const [keyData, setKeyData] = useState(Math.random());
    const [groupInvoiceUUID, setGroupInvoiceUUID] = useState(null);

    useEffect(() => {
        setKeyData(Math.random());
    },[hash]);

    const send = async (data) => {
        _setLoading(true);
        let rs = await Http({
            method: "POST",
            url: RequestAvailableInvoice(),
            data: data
        }).then(response => {
            _setLoading(false);

            let _status = true;
            if (response.data.Status === "REJEITADA") {
                setMessageAlert("Nota rejeitada com sucesso!");
                _setMessageSuccess(true);
                _status = false;
            }

            setGroupInvoiceUUID(response.data.UUID);

            return {
                "status": _status
            }
        }).catch(err => {
            _setLoading(false);
            let errors = [];
            if (err.response.status == 400) {
                if (Array.isArray(err.response.data.message)) {
                    err.response.data.message.map(item => {
                        if (item.field == "Message") {
                            errors.push(item.description.join());
                        }
                    });
                }
            }

            if (errors.length == 0) {
                errors.push("Falha inesperada. Entre em contato com o Administrador. [TIV.001]");
            }

            setMessage(errors);
            _setMessageError(true);

            return {
                "status": false
            }
        });

        return rs;
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const resetInformations = () => {
        //setData({});
        setGroupInvoiceUUID(null);
        // setKeyLoading(Math.random());
        // setStep(0)
    }

    const _setMessageSuccess = (option) => {
        setModalSuccess(option);
        setKeyModalSuccess(Math.random());
    }

    const _setMessageError = (option) => {
        setModalError(option);
        setKeyModalError(Math.random());
    }

    const handleOk = (event) => {
        event.preventDefault();
        _setMessageSuccess(false);
        _setMessageError(false);
        navigate("/notas-em-analise");
    }

    const getSuccessMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <img src={successIcon} className="img-success-icon mb20" alt="Icone sucesso" />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>{messageAlert}!</p>
                </div>

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    const getErrorMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                {message.map((item, index) => {
                    return (
                        <div key={index}>
                            <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>{item}</p>
                        </div>
                    )
                })}

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    const steps = [
        <StepOne
            setStep={setStep}
            keyData={keyData}
            send={send}
            hash={hash}
            width="400px"/>,

        <StepFour
            setStep={setStep}
            groupInvoiceUUID={groupInvoiceUUID}
            resetInformations={resetInformations}
            width="400px"/>
    ];

    return (
        <div>
            <PageLoad open={loading} key={keyLoading} />

            <AlertModal
                open={modalSuccess}
                handleClose={_setMessageSuccess}
                message={null}
                title={null}
                body={getSuccessMessage()}
                key={keyModalSuccess}
            />

            <AlertModal
                open={modalError}
                handleClose={_setMessageError}
                message={null}
                title={null}
                body={getErrorMessage()}
                key={keyModalError}
            />

            <div style={{margin:"auto", paddingTop: "20px", paddingBottom: "20px", width: "800px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Nota em análise</Typography>
            </div>

            <div>
                {steps[step]}
            </div>
        </div>
    );
};

export default InvoiceInAnalysis;