import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Button, TextField, Typography, Select } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../components/views/Theme';
import successIcon from "../../images/success-icon.png"
import AlertModal from "../../components/views/AlertModal";
import WarningIcon from '@material-ui/icons/Warning';
import SelectApplication from "../../components/views/SelectApplication";
import { isEmail } from '../../components/views/Functions';
import { RequestUser, RequestUserUUID } from "../../components/infrastructures/UrlEndpoints";

const User = () => {
    const { hash } = useParams();
    const [title, setTitle] = useState("");
    const [messageAlert, setMessageAlert] = useState("");
    const [uuid, setUUID] = useState("");
    const [name, setName] = useState("");
    const [login, setLogin] = useState("");
    const [email, setEmail] = useState("");
    const [active, setActive] = useState("s");

    const [error, setError] = useState({
        name: {validate: true, message: null},
        login: {validate: true, message: null},
        email: {validate: true, message: null},
        active: {validate: true, message: null}
    });

    useEffect(() => {
        try {
            let dados = JSON.parse(atob(hash));
            setUUID(dados.UUID);
            setName(dados.Name);
            setLogin(dados.Login);
            setEmail(dados.Email);
            setActive(dados.Active);
            setTitle("Alterar usuário");
            setMessageAlert("Dados atualizados com sucesso");
        } catch (error) {
            setTitle("Cadastrar usuário");
            setMessageAlert("Dados salvos com sucesso");
        }
    }, [hash])

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());

    const [modalSuccess, setModalSuccess] = useState(false);
    const [keyModalSuccess, setKeyModalSuccess] = useState(Math.random());

    const [modalError, setModalError] = useState(false);
    const [keyModalError, setKeyModalError] = useState(Math.random());

    const [message, setMessage] = useState([]);

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (name === "") {
            _error.name.validate = false;
            _error.name.message = "Campo nome é obrigatório.";
            _verify = true;
        }

        if (login === "") {
            _error.login.validate = false;
            _error.login.message = "Campo login é obrigatório.";
            _verify = true;
        }

        if (email.trim() !== "") {
            if (!isEmail(email)) {
                _error.email.validate = false;
                _error.email.message = "Email inválido";
                _verify = true;
            }
        }

        if (active === "") {
            _error.active.validate = false;
            _error.active.message = "Campo login é obrigatório.";
            _verify = true;
        }

        setError(_error);

        return _verify;
    }

    const handleSubmitForm = (event) => {
        debugger;
        event.preventDefault();

        if (validateForm()) return;

        _setLoading(true);

        let request = {
            method: `POST`,
            url: RequestUser(),
            data: {
                Name: name,
                Login: login,
                Email: email,
                Active: active
            }
        }

        if (uuid) {
            request.method = `PUT`;
            request.url = RequestUserUUID(uuid);
        }


        Http(
            request
        ).then(response => {
            _setLoading(false);
            _setMessageSuccess(true);
        }).catch(err => {
            _setLoading(false);
            let message = ["Falha inesperada. Entre em contato com o Administrador."];
            if (Array.isArray(err.response.data)) {
                let _error = {...error};
                err.response.data.map(item => {
                    _error[item.Field.toLowerCase()] = {
                        validate: false,
                        message: item.Message
                    }
                });
                setError(_error);

                message = [];
                message.push("Não foi possível salvar/atualizar as informações.");
                message.push("Verifique e corriga a informação.");
                message.push("Ao final reenvie os dados.");
            }
            setMessage(message);
            _setMessageError(true);
        });
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const _setMessageSuccess = (option) => {
        setModalSuccess(option);
        setKeyModalSuccess(Math.random());
    }

    const _setMessageError = (option) => {
        setModalError(option);
        setKeyModalError(Math.random());
    }

    const handleOk = (event) => {
        event.preventDefault();
        _setMessageSuccess(false);
        _setMessageError(false);
    }

    const getSuccessMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <img src={successIcon} className="img-success-icon mb20" alt="Icone sucesso" />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>{messageAlert}!</p>
                </div>

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    const getErrorMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                {message.map((item, index) => {
                    return (
                        <div key={index}>
                            <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>{item}</p>
                        </div>
                    )
                })}

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div style={{width: "400px", margin: "auto"}}>
            <form>
                <PageLoad open={loading} key={keyLoading} />

                <AlertModal
                    open={modalSuccess}
                    handleClose={_setMessageSuccess}
                    message={null}
                    title={null}
                    body={getSuccessMessage()}
                    key={keyModalSuccess}
                />

                <AlertModal
                    open={modalError}
                    handleClose={_setMessageError}
                    message={null}
                    title={null}
                    body={getErrorMessage()}
                    key={keyModalError}
                />

                <div style={{margin:"auto", paddingTop: "20px", paddingBottom: "20px"}}>
                    <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>{title}</Typography>
                </div>

                <ThemeProvider theme={Theme}>
                    <TextField
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        onFocus={(event) => {
                            let _error = {...error};
                            _error.name.validate = true;
                            _error.name.message = "";
                            setError(_error);
                        }}
                        error={!error.name.validate}
                        helperText={error.name.message}
                        color="primary"
                        id="name"
                        name="name"
                        label="Nome"
                        size="small"
                        margin="dense"
                        type="text"
                        placeholder="Digite seu nome"
                        fullWidth
                    />

                    <TextField
                        value={login}
                        onChange={(event) => setLogin(event.target.value)}
                        onFocus={(event) => {
                            let _error = {...error};
                            _error.login.validate = true;
                            _error.login.message = "";
                            setError(_error);
                        }}
                        error={!error.login.validate}
                        helperText={error.login.message}
                        color="primary"
                        id="login"
                        name="login"
                        label="Login"
                        size="small"
                        margin="dense"
                        type="text"
                        placeholder="Digite seu login"
                        fullWidth
                    />

                    <TextField
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        onFocus={(event) => {
                            let _error = {...error};
                            _error.email.validate = true;
                            _error.email.message = "";
                            setError(_error);
                        }}
                        error={!error.email.validate}
                        helperText={error.email.message}
                        color="primary"
                        id="email"
                        name="email"
                        label="E-mail"
                        size="small"
                        margin="dense"
                        type="text"
                        placeholder="Digite seu e-mail"
                        fullWidth
                    />

                    <SelectApplication
                        label="Ativo"
                        value={active}
                        error={error.active}
                        onChange={setActive}>
                        <option value="s">Sim</option>
                        <option value="n">Não</option>
                    </SelectApplication>

                </ThemeProvider>

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handleSubmitForm}>
                        Salvar
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default User;