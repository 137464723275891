import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Http from "../../components/infrastructures/Http";
import PageLoad from "../../components/views/PageLoad";
import { Button, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import Theme from '../../components/views/Theme';
import Certificates from "../../components/views/Certificates";
import { RequestListCouponsByGroupInvoiceUUID, RequestListInvoicesByGroupInvoiceUUID, RequestCancelAllInvoices } from '../../components/infrastructures/UrlEndpoints';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import { numberToMoney } from "../../components/views/Functions";
import AlertModal from "../../components/views/AlertModal";

const ListCertificatesAndInvoicesToCancel = () => {

    let navigate = useNavigate();

    const { hash } = useParams();

    const [loading, setLoading] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [modalConfirm, setModalConfirm] = useState(false);
    const [keyModalConfirm, setKeyModalConfirm] = useState(Math.random());
    const [modalSuccessCancel, setModalSuccessCancel] = useState(false);
    const [keySuccessCancel, setKeySuccessCancel] = useState(Math.random());
    const [groupInvoiceUUID, setGroupInvoiceUUID] = useState(null);

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            try {
                let _params = JSON.parse(atob(hash));
                await getListCouponsByGroupInvoiceUUID(_params.GroupInvoiceUUID);
                await getListInvoicesByGroupInvoiceUUID(_params.GroupInvoiceUUID);
                await setGroupInvoiceUUID(_params.GroupInvoiceUUID);
            } catch (error) {
                alert("Falha inesperada. Entre em contato com o adminstrador.");
            }
            _setLoading(false);
        }

        processResquetAPI()
    }, [hash]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getListCouponsByGroupInvoiceUUID = async (uuid) => {
        Http({
            method: "GET",
            url: RequestListCouponsByGroupInvoiceUUID(uuid)
        }).then(response => {
            setCoupons(response.data);
        }).catch(err => {
            alert("Falha inesperada. Entre em contato com o adminstrador.");
            setCoupons([])
        });
    }

    const getListInvoicesByGroupInvoiceUUID = async (uuid) => {
        Http({
            method: "GET",
            url: RequestListInvoicesByGroupInvoiceUUID(uuid)
        }).then(response => {
            const invs = response.data.map(item => {
                item.Value = numberToMoney(item.Value);
                return item;
            })
            setInvoices(invs);
        }).catch(err => {
            alert("Falha inesperada. Entre em contato com o adminstrador.");
            setInvoices([])
        });
    }

    const handleCancelCertificates = async () => {
        setModalConfirm(true);
        setKeyModalConfirm(Math.random());
    }

    const handleCancel = (event) => {
        event.preventDefault();
        console.log("groupInvoiceUUID", groupInvoiceUUID);
        _setLoading(true)
        Http({
            method: "PUT",
            url: RequestCancelAllInvoices(groupInvoiceUUID)
        }).then(response => {
            _setLoading(false);
            setModalConfirm(false);
            setKeyModalConfirm(Math.random());
            setModalSuccessCancel(true);
            setKeySuccessCancel(Math.random());
        }).catch(err => {
            _setLoading(false);
            setModalConfirm(false);
            setKeyModalConfirm(Math.random());
            alert("Falha inesperada. Entre em contato com o Administrador.");
            console.log("err", err);
        });
    }

    const handleReprocessing = async () => {
        navigate(`/reprocessar/${groupInvoiceUUID}`);
    }

    const handleNotCancel = (event) => {
        event.preventDefault();
        setModalConfirm(false);
        setKeyModalConfirm(Math.random());
    }

    const handleCloseModalSuccessCancel = () => {
        setModalSuccessCancel(false);
        setKeySuccessCancel(Math.random());
        navigate(`/`);
    }

    const getMessageConfirm = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Você cancelar essas notas e certificados?</p>
                </div>

                <br />

                <div style={{display: "grid", gridGap: "4%", gridTemplateColumns: "48% 48%", marginBottom: "15px"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button style={{...Theme.palette.buttonWhite, width: "100%"}} onClick={(event) => handleNotCancel(event)}>
                            Não
                        </Button>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button style={{...Theme.palette.buttonRed, width: "100%"}} onClick={(event) => handleCancel(event)}>
                            Sim
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const columns = [
        {
            id: 'Document',
            label: 'Documento',
            align: 'left'
        },
        {
            id: 'Value',
            label: 'Valor',
            align: 'right'
        },
        {
            id: 'DescriptionType',
            label: 'Tipo',
            align: 'left'
        },
        {
            id: 'Place',
            label: 'Lugar',
            align: 'left'
        }
    ];

    return (
        <div style={{width: "800px", margin: "auto"}}>

            <PageLoad open={loading} key={keyLoading} />

            <AlertModal
                open={modalConfirm}
                handleClose={null}
                message={null}
                title={null}
                body={getMessageConfirm()}
                key={keyModalConfirm}
            />

            <AlertModal
                open={modalSuccessCancel}
                handleClose={() => handleCloseModalSuccessCancel()}
                message="Notas e certificados cancelados com sucesso."
                title="Mensagem"
                key={keySuccessCancel}
            />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Dados para cancelamento</Typography>
            </div>

            <br />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Certificados</Typography>
            </div>

            {(coupons.length !== 0) && (
                <div style={{width: "400px", display: "grid", gridGap: "10px", gridTemplateColumns: "repeat(4, 100px)"}}>

                    {coupons.map((item, index) => {
                        return <Certificates coupon={item.Certificate} key={index} />
                    })}
                </div>
            )}

            <br />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Notas fiscais</Typography>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={invoices}/>
            </div>

            <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handleCancelCertificates}>
                    Cancelar dados
                </Button>
            </div>

            {/* <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                <Button style={{...Theme.palette.buttonWhite, ...Theme.palette.spaceBottom}} onClick={handleReprocessing}>
                    Reprocessar dados
                </Button>
            </div> */}

            <br />
        </div>
    )
}

export default ListCertificatesAndInvoicesToCancel;