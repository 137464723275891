import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Button, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../components/views/Theme';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import BlockIcon from '@material-ui/icons/Block';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { validateCpf } from "../../components/views/Functions";
import { maskCpf } from "../../components/views/Masks";
import AlertModal from "../../components/views/AlertModal";
import WarningIcon from '@material-ui/icons/Warning';
import { RequestListTaxPayers, RequestTaxPayerBlockedUUID } from "../../components/infrastructures/UrlEndpoints";

const TaxPayers = () => {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [taxPayers, setTaxPayers] = useState([]);
    const [list, setList] = useState([]);

    const [name, setName] = useState("");
    const [cpf, setCpf] = useState("");
    const [blockUUID, setBlockUUId] = useState("");
    const [blockName, setBlockName] = useState("");

    const [modalConfirm, setModalConfirm] = useState(false);
    const [keyModalConfirm, setKeyModalConfirm] = useState(Math.random());

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getTaxPayers();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getTaxPayers = async () => {
        await Http({
            method: "GET",
            url: RequestListTaxPayers(),
        }).then(response => {
            let _taxPayers = response.data.map(item => {
                return {
                    Option: getButtons(item),
                    Name: item.Name,
                    Cpf: item.Cpf,
                    Telefone: item.Fone,
                    Celular: item.Celular
                }
            })
            setTaxPayers(_taxPayers);
            setList(_taxPayers)
        }).catch(err => {
            setTaxPayers([]);
            setList([]);
        });
    }

    const handleEdit = (event, item) => {
        event.preventDefault();
        let hash = btoa(JSON.stringify(item));
        navigate(`/contribuinte/${hash}`);
    }

    const handleCancelCoupons = (event, item) => {
        console.log('item', item);
        event.preventDefault();
        let hash = btoa(JSON.stringify(item));
        navigate(`/cancelar-certificados/${hash}`);
    }

    const handleBlock = (event, item) => {
        setBlockUUId(item.UUID);
        setBlockName(item.Name);
        setModalConfirm(true);
        setKeyModalConfirm(Math.random());
    }

    const handleAlterPassword = (event, item) => {
        event.preventDefault();
        let hash = btoa(JSON.stringify({
            UUID: item.UUID,
            Name: item.Name
        }));
        navigate(`/alterar-senha-contribuinte/${hash}`);
    }

    const handleSearch = (event) => {
        event.preventDefault();
        let _list = [...list];
        if (name.trim() !== "") {
            _list = _list.filter(item => item.Name.toUpperCase().indexOf(name.trim().toUpperCase()) >= 0);
        }

        if (cpf.trim() !== "") {
            let _cpf = cpf.split(".").join("").split("-").join("");
            _list = _list.filter(item => item.Cpf.toUpperCase().indexOf(_cpf) >= 0);
        }

        setTaxPayers(_list);
    }

    const handleClearSearch = (event) => {
        event.preventDefault();
        setName("");
        setCpf("");
        let _list = [...list];
        setTaxPayers(_list);
    }

    const handleBlockTaxPayer = (event) => {
        event.preventDefault();
        Http({
            method: "PUT",
            url: RequestTaxPayerBlockedUUID(blockUUID)
        }).then(response => {
            setModalConfirm(false);
            setKeyModalConfirm(Math.random());
        }).catch(err => {
            setModalConfirm(false);
            setKeyModalConfirm(Math.random());
        });
    }

    const handleNotBlock = (event) => {
        event.preventDefault();
        setModalConfirm(false);
        setKeyModalConfirm(Math.random());
    }

    const getButtons = (item) => {
        return (
            <>
                <IconButton aria-label="delete" onClick={(event) => handleEdit(event, item)}>
                    <EditIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
                <IconButton aria-label="delete" onClick={(event) => handleAlterPassword(event, item)}>
                    <LockIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
                <IconButton aria-label="delete" onClick={(event) => handleBlock(event, item)}>
                    <BlockIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
                <IconButton aria-label="delete" onClick={(event) => handleCancelCoupons(event, item)}>
                    <ReceiptIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
            </>
        );
    }

    const columns = [
        {
            id: 'Option',
            label: 'Opções'
        },
        {
            id: 'Name',
            label: 'Nome',
            align: 'left'
        },
        {
            id: 'Cpf',
            label: 'Cpf',
            align: 'right'
        },
        {
          id: 'Fone',
          label: 'Telefone',
          align: 'right'
        },
        {
            id: 'Celular',
            label: 'Celular',
            align: 'right'
        }
    ];

    const getMessageConfirm = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Você deseja bloquer o contribuinte {blockName}?</p>
                </div>

                <br />

                <div style={{display: "grid", gridGap: "4%", gridTemplateColumns: "48% 48%", marginBottom: "15px"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button style={{...Theme.palette.buttonWhite, width: "100%"}} onClick={(event) => handleNotBlock(event)}>
                            Não
                        </Button>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button style={{...Theme.palette.buttonRed, width: "100%"}} onClick={(event) => handleBlockTaxPayer(event)}>
                            Sim
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{width: "900px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <AlertModal
                open={modalConfirm}
                handleClose={null}
                message={null}
                title={null}
                body={getMessageConfirm()}
                key={keyModalConfirm}
            />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Contribuintes</Typography>
            </div>

            <div style={{display: "grid", gridGap: "5%", gridTemplateColumns: "30% 25% 15% 15%", marginBottom: "15px"}}>
                <div>
                    <ThemeProvider theme={Theme}>
                        <TextField
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            color="primary"
                            id="name"
                            name="name"
                            label="Nome do contribuinte"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite o nome do contribuinte"
                            fullWidth
                        />
                    </ThemeProvider>
                </div>

                <div>
                    <ThemeProvider theme={Theme}>
                        <TextField
                            value={cpf}
                            onKeyDown={(event) => {
                                if (event.code === "KeyV") {
                                    navigator
                                        .clipboard
                                        .readText()
                                        .then((copiedText) => {
                                            if (validateCpf(copiedText)) {
                                                let _cpf = maskCpf(copiedText, null);
                                                setCpf(_cpf);
                                            }
                                        });
                                }
                            }}
                            onKeyUp={(event) => {
                                event.preventDefault();
                                let _cpf = maskCpf(cpf, event.key);
                                setCpf(_cpf);
                            }}
                            onBlur={(event) => {
                                if (cpf === "___.___.___-__") {
                                    setCpf("");
                                }
                            }}
                            color="primary"
                            id="cpf"
                            name="cpf"
                            label="Cpf do contribuinte"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite o cpf do contribuinte"
                            fullWidth
                        />
                    </ThemeProvider>
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button style={{...Theme.palette.buttonRed, padding:"0px", width: "100%"}} onClick={(event) => handleSearch(event)}>
                        Buscar
                    </Button>
                </div>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button style={{...Theme.palette.buttonWhite, padding:"0px", width: "100%"}} onClick={(event) => handleClearSearch(event)}>
                        Limpar
                    </Button>
                </div>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={taxPayers}/>
                {/* <Sortable /> */}
            </div>
        </div>
    );
};

export default TaxPayers;