import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import StickyHeadTable from '../../../components/views/StickyHeadTable';
import { getDateFormat } from '../../../components/views/Functions';

const StepThree = (props) => {

    const [awards, setAwards] = useState([]);

    let keyData = props.keyData;

    useEffect(() => {
        let _date = getDateFormat(props.data.AwardDate);
        let _awards = [...props.data.Awards];
        _awards.reverse();
        _awards = _awards.map((item, index) => {
            return {
                Order: (index + 1),
                Date: _date,
                Award: item.award,
                Special: (item.special === "s") ? "Sim" : "Não"
            };
        });
        setAwards(_awards);
    }, [keyData]);

    const columns = [
        {
            id: 'Date',
            label: 'Data',
            align: 'left'
        },
        {
            id: 'Order',
            label: 'Ordem de sorteio',
            align: 'left'
        },
        {
            id: 'Award',
            label: 'Prêmio',
            align: 'left'
        },
        {
            id: 'Special',
            label: 'Especial',
            align: 'left'
        }
    ];

    const handleSubmitForm = (event) => {
        event.preventDefault();
        props.send();
    }

    return (
        <div key={props.keyData} style={{width: props.width, margin: "auto", marginTop: "40px"}}>
            <div>
                <StickyHeadTable columns={columns} rows={awards}/>
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.mt20}} onClick={handleSubmitForm}>
                    Salvar
                </Button>
            </div>
        </div>
    );
}

export default StepThree;
