import React, { useState, useEffect } from "react";
import { Button } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import { useNavigate } from 'react-router-dom';
import Http from "../../../components/infrastructures/Http";
import { RequestListTaxPayers } from "../../../components/infrastructures/UrlEndpoints";
import AutocompleteApplication from "../../../components/views/AutocompleteApplication";
import PageLoad from "../../../components/views/PageLoad";
import { formatCpf } from "../../../components/views/Functions";

import { ThemeProvider } from '@material-ui/styles';

const StepTwo = (props) => {

    let navigate = useNavigate();
    const [taxPayers, setTaxPayers] = useState([]);
    const [taxPayer, setTaxPayer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());

    const setInformation = (item) => {
        if (item === null || typeof item.UUID === "undefined") {
            setTaxPayer(null);
        } else {
            setTaxPayer(item);
        }
    }

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getTaxPayers();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);

    const getTaxPayers = async () => {
        await Http({
            method: "GET",
            url: RequestListTaxPayers(),
        }).then(response => {
            let _taxPayers = response.data.map(item => {
                item.title = `${item.Name} - ${formatCpf(item.Cpf)}`;
                return item;
            })
            setTaxPayers(_taxPayers);
        }).catch(err => {
            setTaxPayers([]);
        });
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const handleNext = async (event) => {
        event.preventDefault();

        props.setPreviewData({
            UUID: taxPayer.UUID,
            Id: taxPayer.Id,
            Name: taxPayer.Name
        });

        props.setStep(2);
    }

    return (
        <div style={{width: props.width, margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <ThemeProvider theme={Theme}>
                <AutocompleteApplication
                    label="Contribuintes"
                    options={taxPayers}
                    setValue={setInformation}
                />
            </ThemeProvider>

            {(taxPayer) && (
                <div>
                    <div style={{...Theme.palette.divSpaceLink, ...Theme.palette.divCenter}}>
                        <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleNext(event)}>
                            Próximo
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default StepTwo;