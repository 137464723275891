import React from 'react';
import { Button } from '@material-ui/core';
import Theme from './Theme';

const AbaHeaderRegisterInvoices = (props) => {
    return (
        <div>
            <div style={{display: "grid", gridTemplateColumns: "58% 20% 20%", gridGap: "1%"}}>
                <div>
                    <div style={{color: "#707070", fontSize: "12px"}}>Saldo</div>
                    <div style={{color: "#ed3136", fontSize: "12px", fontWeight: "bold"}}>R$ {props.balance}</div>
                </div>
                <div style={{display: "flex"}}>
                    <Button style={{...Theme.palette.btnRedRegisterInvoice}} onClick={(event) => props.handleNewInvoice(event)}>
                        Cadastrar Nota
                    </Button>
                </div>
                <div style={{display: "flex"}}>
                    <Button style={{...Theme.palette.btnWhiteRegisterInvoice}} onClick={(event) => props.handleSave(event)}>
                        Salvar
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AbaHeaderRegisterInvoices;