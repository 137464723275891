/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../components/views/Theme';

const filter = createFilterOptions();

export default function AutocompleteApplication(props) {
    const [value, setValue] = React.useState(props.value || null);
    const options = props.options || [];

    return (
        <ThemeProvider theme={Theme}>

            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    let _item = null;
                    if (typeof newValue === 'string') {
                        setValue({
                            title: newValue,
                        });
                        _item = newValue;
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setValue({
                            title: newValue.inputValue,
                        });
                        _item = newValue.inputValue;
                    } else {
                        setValue(newValue);
                        _item = newValue;
                    }
                    props.setValue(_item);
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            title: `"${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={options}
                getOptionLabel={(option) => {
                    console.log();
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                }}
                renderOption={(option) => option.title}
                style={{ width: "100%" }}
                className="AutocompleteApplication"
                renderInput={(params) => (
                    <TextField {...params} label={props.label} color="primary" variant="standard" margin="dense"/>
                )}
            />
        </ThemeProvider>
    );
}