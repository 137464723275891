import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Http from "../../components/infrastructures/Http";
import PageLoad from "../../components/views/PageLoad";
import { Button, Typography, TextField} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../components/views/Theme';
import Certificates from "../../components/views/Certificates";
import { RequestCouponsCertificates } from '../../components/infrastructures/UrlEndpoints';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import { numberToMoney } from "../../components/views/Functions";
import AlertModal from "../../components/views/AlertModal";


const ListCertificates = () => {

    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [modalConfirm, setModalConfirm] = useState(false);
    const [keyModalConfirm, setKeyModalConfirm] = useState(Math.random());
    const [modalSuccessCancel, setModalSuccessCancel] = useState(false);
    const [keySuccessCancel, setKeySuccessCancel] = useState(Math.random());
    const [groupInvoiceUUID, setGroupInvoiceUUID] = useState(null);

    const [coupon, setCoupon] = useState("");
    const [coupons, setCoupons] = useState([]);
    const [couponsSearch, setCouponsSearch] = useState([]);

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            try {
                await getListCertificates();
            } catch (error) {
                alert("Falha inesperada. Entre em contato com o adminstrador.");
            }
            _setLoading(false);
        }

        processResquetAPI()
    }, []);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getListCertificates = async () => {
        Http({
            method: "GET",
            url: RequestCouponsCertificates()
        }).then(response => {
            setCoupons(response.data);
            setCouponsSearch(response.data);
        }).catch(err => {
            alert("Falha inesperada. Entre em contato com o adminstrador.");
            setCoupons([])
            setCouponsSearch([])
        });
    }

    const handleSearch = (event) => {
        event.preventDefault();
        let _list = [...couponsSearch];
        if (coupon.trim() !== "") {
            _list = _list.filter(item => item.Certificate.toUpperCase().indexOf(coupon.trim().toUpperCase()) >= 0);
        }

        setCoupons(_list);
    }

    const handleClearSearch = (event) => {
        event.preventDefault();
        setCoupon("");
        let _list = [...couponsSearch];
        setCouponsSearch(_list);
        setCoupons(_list);
    }

    const columns = [
        {
            id: 'Certificate',
            label: 'Certificado',
            align: 'left'
        },
        {
            id: 'Name',
            label: 'Contribuinte',
            align: 'left'
        },
        {
            id: 'Status',
            label: 'Status',
            align: 'Right'
        }
    ];

    return (
        <div style={{width: "800px", margin: "auto"}}>

            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Certificados</Typography>
            </div>

            <div style={{display: "grid", gridGap: "5%", gridTemplateColumns: "60% 15% 15%", marginBottom: "15px"}}>
                <div>
                    <ThemeProvider theme={Theme}>
                        <TextField
                            value={coupon}
                            onChange={(event) => setCoupon(event.target.value)}
                            color="primary"
                            id="coupon"
                            name="coupon"
                            label="Certificado do contribuinte"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite o certificado do contribuinte"
                            fullWidth
                        />
                    </ThemeProvider>
                </div>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button style={{...Theme.palette.buttonRed, padding:"0px", width: "100%"}} onClick={(event) => handleSearch(event)}>
                        Buscar
                    </Button>
                </div>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button style={{...Theme.palette.buttonWhite, padding:"0px", width: "100%"}} onClick={(event) => handleClearSearch(event)}>
                        Limpar
                    </Button>
                </div>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={coupons}/>
            </div>

        </div>
    )
}

export default ListCertificates;