export const RequestLogin = () => `/administration`;

// contribuintes
export const RequestListTaxPayers = ()                      => `/api/v1/taxpayers`;
export const RequestTaxPayer = ()                           => `/api/v1/taxpayers`;
export const RequestTaxPayerUUID = (uuid)                   => `/api/v1/taxpayers/${uuid}`;
export const RequestTaxPayerChangePasswordUUID = (uuid)     => `/api/v1/taxpayers/password/${uuid}`;
export const RequestTaxPayerBlockedUUID = (uuid)            => `/api/v1/taxpayers/blocked/${uuid}`;
export const RequestTaxpayersInvoicesChanges = ()           => `/api/v1/taxpayers/invoices/changes`;
export const RequestTaxPayerByGroupInvoiceUUID = (uuid)     => `/api/v1/taxpayer/group-invoice-uuid/${uuid}`;

// cupons
export const RequestCouponsByGroupInvoiceUUID = (uuid)      => `/api/v1/coupons/${uuid}`;
export const RequestPrintCouponsByGroupInvoiceUUID = (uuid) => `/api/v1/coupons/print/${uuid}`;
export const RequestTaxpayerCouponsByUUID = (uuid)          => `/api/v1/coupons/taxpayer/${uuid}`;
export const RequestDownload = (file)                       => `/api/v1/coupons/file/${file}`;
export const RequestListCouponsByGroupInvoiceUUID = (uuid)  => `/api/v1/coupons/groupinvoice/${uuid}`;
export const RequestCouponsPrize = ()                       => `/api/v1/coupons/prize`;
export const RequestCouponsCertificates = ()                => `/api/v1/coupons/all/certificates`;
export const RequestTaxPayerCouponsGroupinvoices = (uuid)   => `/api/v1/coupons/groupinvoices-taxpayer/${uuid}`;


// localização
export const RequestCountries = ()                          => `/api/v1/countries`;
export const RequestStates = ()                             => `/api/v1/states`;
export const RequestCities = ()                             => `/api/v1/cities`;

// notas
export const RequestRegisterInvoices = ()                   => `/api/v1/invoices/register`;
export const RequestAvailableInvoice = ()                   => `/api/v1/invoices/available-invoice`;
export const RequestAccessFile = ()                         => `/api/v1/invoices/access-file`;
export const RequestInvoicesStatus = (status)               => `/api/v1/invoices${status}`;
export const RequestViewPdf = (file, hash)                  => `/api/v1/invoices/file/pdf/${file}?token=${hash}`;
export const RequestViewImage = (file, hash)                => `/api/v1/invoices/file/image/${file}?token=${hash}`;
export const RequestInvoicesTaxPayer = (uuid)               => `api/v1/invoices/taxpayer/${uuid}`;
export const RequestCancelInvoices = ()                     => `api/v1/invoices/cancel/group-invoice`;
export const RequestListInvoicesByGroupInvoiceUUID = (uuid) => `/api/v1/invoices/groupinvoice/${uuid}`;
export const RequestCancelAllInvoices              = (uuid) => `/api/v1/invoices/cancel/all/${uuid}`;

// usuário
export const RequestUser = ()                               => `/api/v1/users`;
export const RequestUserUUID = (uuid)                       => `/api/v1/users/${uuid}`;
export const RequestUserChangePasswordUUID = (uuid)         => `/api/v1/users/password/${uuid}`;
export const RequestUserLoggedData = ()                     => `/api/v1/users/logged/data`;
export const RequestUserLogout = ()                         => `/api/v1/users/logout`;


// saldo
export const RequestBalaceTaxPayer = (uuid)                 => `/api/v1/balances/${uuid}`;
export const RequestBalaceReprocessing = (uuid)             => `/api/v1/balances/reprocessing/${uuid}`;

// premio
export const RequestAwards = ()                             => `/api/v1/awards`;
export const RequestPrizeAwards = (date)                    => `/api/v1/awards/prize-award-by-date/${date}`;
export const RequestConfirmWinner = ()                      => `/api/v1/awards/confirm-winner`;



// relatorio
export const RequestDashboard = ()                          => `/api/v1/reports`;
