import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Typography, Button } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import WarningIcon from '@material-ui/icons/Warning';
import AlertModal from "../../components/views/AlertModal";

import { RequestUser, RequestUserUUID } from "../../components/infrastructures/UrlEndpoints";

const Users = () => {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [users, setUsers] = useState([]);
    const [userUUID, setUserUUID] = useState("");
    const [userName, setUserName] = useState("");
    const [modalConfirm, setModalConfirm] = useState(false);
    const [keyModalConfirm, setKeyModalConfirm] = useState(Math.random());

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getUsers();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getUsers = async () => {
        await Http({
            method: "GET",
            url: RequestUser(),
        }).then(response => {
            let users = response.data.map(item => {
                return {
                    Option: getButtons(item),
                    Name: item.Name,
                    Login: item.Login,
                    Email: item.Email
                }
            })
            setUsers(users);
        }).catch(err => {
            setUsers([]);
        });
    }

    const handleEdit = (event, item) => {
        event.preventDefault();
        let hash = btoa(JSON.stringify(item));
        navigate(`/usuario/${hash}`);
    }

    const handleDelete = (event, item) => {
        setUserUUID(item.UUID);
        setUserName(item.Name);
        setModalConfirm(true);
        setKeyModalConfirm(Math.random());
    }

    const handleDeleteUser = async (event) => {
        _setLoading(true);
        await Http({
            method: "DELETE",
            url: RequestUserUUID(userUUID),
        }).then(response => {
            _setLoading(false);
            handleCloseAlert();
            getUsers();
        }).catch(err => {
            _setLoading(false);
            handleCloseAlert();
            getUsers();
        });
    }

    const handleAlterPassword = (event, item) => {
        event.preventDefault();
        let hash = btoa(JSON.stringify({
            UUID: item.UUID,
            Name: item.Name
        }));
        navigate(`/alterar-senha/${hash}`);
    }

    const getButtons = (item) => {
        return (
            <>
                <IconButton aria-label="delete" onClick={(event) => handleEdit(event, item)}>
                    <EditIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
                <IconButton aria-label="delete" onClick={(event) => handleDelete(event, item)}>
                    <DeleteIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
                <IconButton aria-label="delete" onClick={(event) => handleAlterPassword(event, item)}>
                    <LockIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
            </>
        );
    }

    const columns = [
        {
            id: 'Option',
            label: 'Opções',
            width: 30,
        },
        {
            id: 'Name',
            label: 'Nome',
            align: 'left',
            width: 30,
        },
        {
            id: 'Login',
            label: 'Login',
            align: 'left',
            width: 10,
        },
        {
          id: 'Email',
          label: 'E-mail',
          align: 'left',
          width: 30,
        }
    ];

    const handleCloseAlert = () => {
        setModalConfirm(false);
        setKeyModalConfirm(Math.random());
    }

    const getMessageConfirm = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Você deseja deletar o usuário {userName}?</p>
                </div>

                <br />

                <div style={{display: "grid", gridGap: "4%", gridTemplateColumns: "48% 48%", marginBottom: "15px"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button style={{...Theme.palette.buttonWhite, width: "100%"}} onClick={(event) => handleCloseAlert(event)}>
                            Não
                        </Button>
                    </div>

                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Button style={{...Theme.palette.buttonRed, width: "100%"}} onClick={(event) => handleDeleteUser(event)}>
                            Sim
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{width: "800px", margin: "auto"}}>

            <AlertModal
                open={modalConfirm}
                handleClose={null}
                message={null}
                title={null}
                body={getMessageConfirm()}
                key={keyModalConfirm}
            />

            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Usuários</Typography>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={users}/>
            </div>
        </div>
    );
};

export default Users;