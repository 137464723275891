const validator = require('validator');

export const isNumber = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

export const inArray = (word, arr) => {
    return (arr.indexOf(word) >= 0);
}

export const numberFormat = (number, decimals, dec_point, thousands_sep) => {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const validateCpf = (strCPF) => {
    strCPF = strCPF.split(".").join("");
    strCPF = strCPF.split("-").join("");

    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;
    if (strCPF === "11111111111") return false;
    if (strCPF === "22222222222") return false;
    if (strCPF === "33333333333") return false;
    if (strCPF === "44444444444") return false;
    if (strCPF === "55555555555") return false;
    if (strCPF === "66666666666") return false;
    if (strCPF === "77777777777") return false;
    if (strCPF === "88888888888") return false;
    if (strCPF === "99999999999") return false;

    for (var i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}

export const validateFone = (fone) => {
    let numerics = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let _fone = fone;
    _fone = _fone.split("").filter(item => inArray(item, numerics)).map(item => item);
    _fone = _fone.join("");
    return (_fone.length < 10);
}

export const convertToDecimal = (value) => {
    value = value.split(".").join("");
    value = value.replace(",", ".");
    return parseFloat(value);
}

export const getTypeFile = (fileName) => {
    let fileExtension = fileName.split(".");
    fileExtension = fileExtension[fileExtension.length - 1];
    fileExtension = fileExtension.toUpperCase();
    return fileExtension;
}

export const formatCpf = (value) => {
    if (typeof value !== "undefined" && value !== "") {
        value = value.split(".").join("").split("-").join("");
        let _cpf = value.padEnd(11, '_');
        _cpf = _cpf.split("");
        let _partOne = _cpf[0] + "" + _cpf[1] + "" + _cpf[2];
        let _partTwo = _cpf[3] + "" + _cpf[4] + "" + _cpf[5];
        let _partThree = _cpf[6] + "" + _cpf[7] + "" + _cpf[8];
        let _partFour = _cpf[9] + "" + _cpf[10];
        value = _partOne + "." + _partTwo + "." + _partThree + "-" + _partFour;
    }

    return value;
}

export const formatCpfView = (cpf) => {
    let _cpf = cpf.toString().padStart(11,0).split("");
    let _partOne = _cpf[0] + "" + _cpf[1] + "" + _cpf[2];
    let _partTwo = _cpf[3] + "" + _cpf[4] + "" + _cpf[5];
    let _partThree = _cpf[6] + "" + _cpf[7] + "" + _cpf[8];
    let _partFour = _cpf[9] + "" + _cpf[10];
    let value = _partOne + "." + _partTwo + "." + _partThree + "-" + _partFour;
    return value;
}

export const isEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export const formatDateDB = (date) => {
    if (typeof date !== "undefined" && date !== "" && date !== null) {
        date = date.split("/");
        date =  date[2] + "-" + date[1] + "-" + date[0];
    }
    return date;
}

export const formatDateBR = (date) => {
    if (typeof date !== "undefined" && date !== "" && date !== null) {
        date = date.split("-");
        date = date[2] + "/" + date[1] + "/" + date[0];
    }
    return date;
}

export const formatDateHoraMinBR = (date) => {
    if (typeof date !== "undefined" && date !== "" && date !== null) {
        let hora = date.split("T")[1].substring(0,5);
        date = date.split("T")[0].split("-");
        date = date[2] + "/" + date[1] + "/" + date[0] + " " + hora;
    }
    return date;
}


export const validateDate = (date) => {
    date = formatDateDB(date);
    return validator.isDate(date);
}

export const moneyToNumber = (value) => {
    if (value === null || value === "") return 0;

    if (isNumber(value)) return value;

    value = value.split(".").join("").split(",").join(".");
    value = parseFloat(value);
    return value;
}

export const numberToMoney = (money) => {
    money = numberFormat(money, 2, ',', '.');
    return money;
}

export const getDate = () => {
    let date = new Date();
    return getDateFormat(date);

}

export const getDateFormat = (date) => {
    if (date != null) {
        let day = date.getDate().toString().padStart(2, "0");
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }
    return date;
}

export const encode = ( str ) => {
    //return window.btoa(unescape(encodeURIComponent( str )));
    return btoa(encodeURIComponent(str));
}

export const decode = ( str ) => {
    //return decodeURIComponent(escape(window.atob( str )));
    return decodeURIComponent(atob(str));
}