import React, { Component } from 'react';
import Theme from './Theme';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const SelectApplication = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} error={!props.error.validate}>
            <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
            <Select
                native
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.value}
                margin="dense"
                onChange={(event) => props.onChange(event.target.value)}
            >
                {props.children}
            </Select>
            <FormHelperText>{props.error.message}</FormHelperText>
        </FormControl>
    )
}

export default SelectApplication;