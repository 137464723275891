import { createTheme } from "@material-ui/core/styles";
import { blue, grey } from "@material-ui/core/colors";
import logo from "../../images/image-logo.png";

const Theme = createTheme({
    palette: {
        primary: {
            main: grey[500],
        },
        secondary: {
            main: "#ed3136"
        },
        buttonRed: {
            backgroundColor: "#ed3136",
            color: "#fff",
            border: "1px solid #ed3136",
            borderRadius: 100,
            width: "300px",
            padding: "15px",
            light: "#fff",
            textTransform: "unset"
        },
        buttonRedDisable: {
            backgroundColor: "#f5f5f5",
            color: "rgba(0,0,0,0.25)",
            border: "1px solid #d9d9d9",
            borderRadius: 100,
            width: "300px",
            padding: "15px",
            light: "#fff",
            textTransform: "unset"
        },
        buttonWhite: {
            backgroundColor: "#fff",
            color: "#ed3136",
            border: "1px solid #ed3136",
            borderRadius: 100,
            width: "300px",
            padding: "15px",
            light: "#ed3136",
            textTransform: "unset"
        },
        BodyImage: {
            backgroundImage: `url(${logo})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            height: "100vh"
        },
        HomeButtons: {
            position: "absolute",
            bottom: "20px",
            width: "100%",
            textAlign: "center"
        },
        spaceRight: {
            marginRight: "20px"
        },
        spaceLeft: {
            marginLeft: "20px"
        },
        spaceBottom: {
            marginBottom: "20px"
        },
        divPageLogin: {
            width: "400px",
            margin: "0px auto"
        },
        imgPageLogin: {
            width: "70%",
            marginTop: "20px",
            marginBottom: "20px"
        },
        divCenter: {
            textAlign: "center"
        },
        divSpaceLink: {
            marginTop: "20px",
            marginBottom: "20px"
        },
        link: {
            fontSize: "12px",
            color: "#ed3136",
            cursor: "pointer"
        },
        divSpaceLinkStepInfo: {
            marginTop: "30px",
            marginBottom: "30px",
            textAlign: "center"
        },
        linkStepInfo: {
            fontSize: "14px",
            color: "#ed3136",
            cursor: "pointer"
        },
        titleModel: {
            fontSize: "16px",
            color: "#ed3136",
            fontWeight: "700",
            marginBottom: "30px"
        },
        bodyModel: {
            fontSize: "14px",
            color: "#ed3136"
        },
        spaceButtonOkModal: {
            marginTop: "30px"
        },
        textColorRed: {
            color: "#ed3136",
        },
        textColorWhite: {
            color: "#fff",
        },
        textBold:{
            fontWeight: "bold"
        },
        textSize14: {
            fontSize: "14px",
        },
        textSize50: {
            fontSize: "50px",
        },
        iconUserMenu: {
            fontSize: "150px",
            color: "#fff"
        },
        nameUserMenu: {
            fontSize: "12px",
            color: "#fff",
            textAlign: "center"
        },
        closeMenu: {
            color: "#fff",
            position: "absolute",
            top: "10px",
            right: "10px"
        },
        listMenu: {
            width: "100%",
            color: "#fff"
        },
        mt20: {
            marginTop: "20px"
        },
        mt40: {
            marginTop: "40px"
        },
        mb20: {
            marginBottom: "20px"
        },
        bodySystem: {
            marginTop: "100px",
            marginLeft: "0px"
        },
        buttonRegisterInvoice: {
            backgroundColor: "#fff",
            color: "#ed3136",
            border: "1px solid #ed3136",
            borderRadius: 100,
            padding: "10px 50px",
            light: "#fff",
            textTransform: "unset"
        },
        stepArround: {
            backgroundColor: "#ed3136",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
            borderRadius: "100px",
            color: "#fff"
        },
        stepText: {
            marginLeft: "10px",
            color: "#ed3136"
        },
        stepBetween: {
            width:"2px",
            height:"15px",
            backgroundColor: "#ed3136",
            display:"block",
            marginLeft:"19px"
        },
        tableBorderCollapse: {
            borderCollapse: "collapse"
        },
        termRegister: {
            margin: 0,
            color: "#ed3136",
            fontSize: "14px"
        },
        linkTermRegister: {
            cursor: "pointer",
            textDecoration: "underline #ed3136"
        },
        textTerm: {
            margin: "6px 0px",
            color: "#707070",
            fontSize: "14px"
        },
        divModalRegisterSuccess: {
            textAlign: "center",
        },
        textModalRegisterSuccessPrimary: {
            marginBottom: "0px",
            textAlign: "center",
            color: "#ed3136",
            fontSize: "18px",
            fontWeight: "bold"
        },
        textModalRegisterSuccessSecundary: {
            marginBottom: "0px",
            textAlign: "center",
            color: "#ed3136",
            fontSize: "14px",
        },
        divTitleRegisterInvoices: {
            paddingTop: "40px",
            paddingBottom: "30px",
        },
        subTitleRegisterInvoices: {
            color: "#ed3136",
            fontSize: "14px",
            margin: "0px"
        },
        divUploadInvoices: {
            width: "300px",
            height: "300px",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent:"center",
            margin: "auto",
            border: "2px #ed3136 dotted"
        },
        textUploadInvoices: {
            textAlign: "center",
            lineHeight: "30px",
            color: "#ed3136",
            fontSize: "14px",
        },
        displayNone: {
            display: "none"
        },
        stepActive: {
            width: "10px",
            height: "10px",
            borderRadius: "100px",
            display: "inline-block",
            backgroundColor: "#ed3136",
            marginLeft: "10px"
        },
        stepInactive: {
            width: "10px",
            height: "10px",
            borderRadius: "100px",
            display: "inline-block",
            backgroundColor: "#b1b1b1",
            marginLeft: "10px"
        },
        tableHeader: {
            background: "#fff",
            color: "#ed3136"
        },
        btnAbaRegisterInvoice: {
            color: "red",
            padding: "10px 20px",
            borderRadius: "15px 15px 0px 0px",
            fontSize: "13px",
            fontWeight: "bold",
            cursor: "pointer"
        },
        btnRedRegisterInvoice: {
            boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
            backgroundColor: "#ed3136",
            color: "#fff",
            border: "1px solid #ed3136",
            borderRadius: "20px",
            width: "100%",
            textTransform: "unset"
        },
        btnWhiteRegisterInvoice: {
            boxShadow: "0 3px 6px rgb(0 0 0 / 16%)",
            backgroundColor: "#fff",
            color: "#ed3136",
            border: "1px solid #fff",
            borderRadius: "20px",
            width: "100%",
            textTransform: "unset"
        },
        headerBox: {
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "10px"
        },
        headerTitle: {
            fontSize: "12px",
            color: "#707070"
        },
        headerContent: {
            color: "#ed3136",
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: "5px"
        },
        textTitle: {
            fontWeight: "bold",
            margin: "0px",
            fontSize: "15px"
        },
        textDescription: {
            margin: "0px",
            color: "rgb(0, 0, 0, 0.6)",
            fontSize: "14px"
        },
        textError: {
            display: "block",
            marginTop: "6px",
            color: "#ed3136",
            fontSize: "14px",
            fontWeight: "bold"
        },
        headerSortable: {
            padding: "16px 0px",
            fontSize: "12",
            fontWeight: "bold",
            color: "#ed3136"
        },
        bodySortable: {
            padding: "16px 0px",
            fontSize: "12",
            color: "darkgray",
            cursor: "move"
        }
    },
});

export default Theme;