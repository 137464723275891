import React, { useState, useEffect } from 'react';
import { Button, TextField, Select } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import { ThemeProvider } from '@material-ui/styles';
import { maskCpf } from "../../../components/views/Masks";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { formatCpf, validateCpf, validateDate, formatDateBR, getDateFormat } from '../../../components/views/Functions';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const StepPersonalInformation = (props) => {

    const classes = useStyles();

    const [name, setName] = useState(props.data.Name || "");
    const [cpf, setCpf] = useState(formatCpf(props.data.Cpf) || "");
    const [rg, setRg] = useState(props.data.Rg || "");
    const [dateBorn, setDateBorn] = useState(props.data.DateBorn || "");
    const [sex, setSex] = useState(props.data.Sex || "");
    const [selectedDate, setSelectedDate] = React.useState(null);


    let keyData = props.keyData;
    let errors = props.errors;

    useEffect(() => {
        setName(props.data.Name || "");
        setCpf(formatCpf(props.data.Cpf) || "");
        setRg(props.data.Rg || "");
        setDateBorn(formatDateBR(props.data.DateBorn) || "");
        setSex(props.data.Sex || "");
        if (props.data.DateBorn) {
            setSelectedDate(new Date(props.data.DateBorn + 'T00:00:00'));
        }
    }, [keyData]);

    useEffect(() => {
        let _error = {...error};
        errors.map(err => {
            _error[err.field.toLowerCase()].validate = false;
            _error[err.field.toLowerCase()].message = err.description.join();
        })
        setError(_error);
    }, [errors]);

    const [error, setError] = useState({
        name: {validate: true, message: null},
        cpf: {validate: true, message: null},
        dateBorn: {validate: true, message: null},
        sex: {validate: true, message: null}
    });

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (name.trim() === "") {
            _error.name.validate = false;
            _error.name.message = "Por favor preencha o campo. Campo é obrigatório.";
            _verify = true;
        }

        if (cpf.trim() === "") {
            _error.cpf.validate = false;
            _error.cpf.message = "Por favor, insira seu cpf. Campo é obrigatório.";
            _verify = true;
        } else if (!validateCpf(cpf)) {
            _error.cpf.validate = false;
            _error.cpf.message = "Campo Cpf inválido.";
            _verify = true;
        }

        if (dateBorn.trim() !== "") {
            if (!validateDate(dateBorn)) {
                _error.dateBorn.validate = false;
                _error.dateBorn.message = "Campo data de nascimento inválido.";
                _verify = true;
            }
        }

        setError(_error);
        return _verify;
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        props.setPreviewData({
            Name: name,
            Cpf: cpf,
            Rg: rg,
            DateBorn: dateBorn,
            Sex: sex
        });

        props.setStep(1);
    }

    const cleanSexMessageError = () => {
        let _error = {...error};
        _error.sex.validate = true;
        _error.sex.message = "";
        setError(_error);
    }

    const handleDateChange = (date) => {
        setDateBorn(getDateFormat(date));
        setSelectedDate(date);
    };

    return (
        <div key={props.keyData}>
            <ThemeProvider theme={Theme}>

                <TextField
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.name.validate = true;
                        _error.name.message = "";
                        setError(_error);
                    }}
                    error={!error.name.validate}
                    helperText={error.name.message}
                    color="primary"
                    id="name"
                    name="name"
                    label="Nome"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu nome"
                    fullWidth
                />

                <TextField
                    value={cpf}
                    onChange={(event) => {
                        let _cpf = maskCpf(event.target.value, null);
                        setCpf(_cpf);
                    }}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.cpf.validate = true;
                        _error.cpf.message = "";
                        setError(_error);
                    }}
                    error={!error.cpf.validate}
                    helperText={error.cpf.message}
                    color="primary"
                    id="cpf"
                    name="cpf"
                    label="Cpf"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu Cpf"
                    fullWidth
                />

                <TextField
                    value={rg}
                    onChange={(event) => setRg(event.target.value)}
                    color="primary"
                    id="rg"
                    name="rg"
                    label="RG"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu rg"
                    fullWidth
                />

                <KeyboardDatePicker
                    disableToolbar
                    color="primary"
                    format="dd/MM/yyyy"
                    margin="dense"
                    id="date-picker-inline"
                    label="Data de nascimento"
                    placeholder="Digite a data de nascimento"
                    error={!error.dateBorn.validate}
                    helperText={error.dateBorn.message}
                    value={selectedDate}
                    onChange={handleDateChange}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.dateBorn.validate = true;
                        _error.dateBorn.message = "";
                        setError(_error);
                    }}
                    onBlur={() => {
                        // debugger;
                        // console.log("1", typeof selectedDate, selectedDate);
                        if (selectedDate == null || selectedDate.toString() === "Invalid Date") {
                            let _error = {...error};
                            _error.dateBorn.validate = false;
                            _error.dateBorn.message = "Campo data de nascimento inválido.";
                            setError(_error);
                            setDateBorn("");
                        }
                    }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    fullWidth
                />

                <FormControl className={classes.formControl} error={!error.sex.validate}>
                    <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                    <Select
                        native
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sex}
                        onChange={(event) => {
                            setSex(event.target.value);
                            cleanSexMessageError();
                        }}
                    >
                        <option>Selecione</option>
                        <option value="m">Masculino</option>
                        <option value="f">Feminino</option>
                    </Select>
                    <FormHelperText>{error.sex.message}</FormHelperText>
                </FormControl>
            </ThemeProvider>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.mt20}} onClick={handleSubmitForm}>
                    Próximo
                </Button>
            </div>
        </div>
    );
}

export default StepPersonalInformation;
