import React, {useState} from 'react';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import Theme from './Theme';

const SortableItem = sortableElement(({value, order}) => (
    <li style={{listStyle: "none"}}>
        <div style={{display: "grid", gridTemplateColumns: "20% 50% 28%", gridGap: "1%"}}>
            <div style={Theme.palette.bodySortable}>{order + 1}</div>
            <div style={Theme.palette.bodySortable}>{value.award}</div>
            <div style={Theme.palette.bodySortable}>{value.special === "s" ? "Sim" : "Não"}</div>
        </div>
    </li>
  ));

const SortableContainer = sortableContainer(({children}) => {
  return <ul style={{margin: 0, padding: 0, listStyle: "none"}}>{children}</ul>;
});

const Sortable = (props) => {

    const [items, setItems] = useState(props.itens || []);

    const onSortEnd = ({oldIndex, newIndex}) => {
        let _items = arrayMoveImmutable(items, oldIndex, newIndex);
        setItems(_items);
        props.onSortEnd(_items);
    };

    return (
        <div>
            <div style={{display: "grid", gridTemplateColumns: "20% 50% 28%", gridGap: "1%"}}>
                <div style={Theme.palette.headerSortable}>Ordem</div>
                <div style={Theme.palette.headerSortable}>Prêmio</div>
                <div style={Theme.palette.headerSortable}>Especial</div>
            </div>

            <SortableContainer onSortEnd={onSortEnd}>
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} order={index}/>
                ))}
            </SortableContainer>
        </div>
    )
}

export default Sortable;