export const TOKEN_KEY = "@sistema-admin-nova-santa-rita-premiada";
export const FILE_TOKEN_KEY = "@sistema-admin-nova-santa-rita-premiada-file";
export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_KEY) !== null
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const setTokenFile = token => {
  localStorage.setItem(FILE_TOKEN_KEY, token);
};

export const getTokenFile = () => localStorage.getItem(FILE_TOKEN_KEY);