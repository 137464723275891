import React from 'react';
import Theme from './Theme';

const HeaderRegisterInvoices = (props) => {
    return (
        <div style={{...Theme.palette.headerBox}}>
            <div style={{...Theme.palette.headerTitle}}>{props.title}</div>
            <div style={{...Theme.palette.headerContent}}>{props.content}</div>
        </div>
    )
}

export default HeaderRegisterInvoices;