import React, { useState, useEffect } from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import { ThemeProvider } from '@material-ui/styles';
import { maskRegularFone, maskCelular  } from "../../../components/views/Masks";
import { isEmail } from '../../../components/views/Functions';

const StepContactInformation = (props) => {

    const [fone, setFone] = useState(props.data.Fone || "");
    const [celular, setCelular] = useState(props.data.Celular || "");
    const [email, setEmail] = useState(props.data.Email || "");

    const [error, setError] = useState({
        fone: {validate: true, message: null},
        celular: {validate: true, message: null},
        email: {validate: true, message: null}
    });

    const [foneSelected, setFoneSelected] = useState(false);
    const [celularSelected, setCelularSelected] = useState(false);

    let keyData = props.keyData;

    useEffect(() => {
        setFone(props.data.Fone || "");
        setCelular(props.data.Celular || "");
        setEmail(props.data.Email || "");
    }, [keyData]);

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (fone.trim() === "" && celular.trim() === "") {
            _error.fone.validate = false;
            _error.fone.message = "Por favor preencha o campo. Número do telefone é obrigatório.";
            _verify = true;
        }

        if (email.trim() !== "") {
            if (!isEmail(email)) {
                _error.email.validate = false;
                _error.email.message = "Email inválido";
                _verify = true;
            }
        }

        setError(_error);
        return _verify;
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        props.send({
            Fone: fone,
            Celular: celular,
            Email: email
        });

    }

    return (
        <div>
            <ThemeProvider theme={Theme}>
                <TextField
                    value={fone}
                    // onSelect={(event) => {
                    //     setFoneSelected(event.target.selectionStart === 0 && event.target.selectionEnd === 13);
                    // }}
                    // onKeyDown={(event) => {
                    //     if (foneSelected) setFone("");
                    // }}
                    // onKeyUp={(event) => {
                    //     event.preventDefault();
                    //     let _fone = maskRegularFone(fone, event.key);
                    //     setFone(_fone);
                    // }}
                    // onBlur={(event) => {
                    //     if (fone === "(__)____-____") {
                    //         setFone("");
                    //     }
                    // }}
                    // onFocus={(event) => {
                    //     let _error = {...error};
                    //     _error.fone.validate = true;
                    //     _error.fone.message = "";
                    //     setError(_error);
                    // }}
                    onChange={(event) => {
                        let _fone = maskRegularFone(event.target.value);
                        setFone(_fone);
                    }}
                    error={!error.fone.validate}
                    helperText={error.fone.message}
                    color="primary"
                    id="fone"
                    name="fone"
                    label="Telefone"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu telefone"
                    fullWidth
                />

                <TextField
                    value={celular}
                    // onSelect={(event) => {
                    //     setCelularSelected(event.target.selectionStart === 0 && event.target.selectionEnd === 14);
                    // }}
                    // onKeyDown={(event) => {
                    //     if (celularSelected) setCelular("");
                    // }}
                    // onKeyUp={(event) => {
                    //     event.preventDefault();
                    //     let _celular = maskCelular(celular, event.key);
                    //     setCelular(_celular);
                    // }}
                    // onBlur={(event) => {
                    //     if (celular === "(__)_____-____") {
                    //         setCelular("");
                    //     }
                    // }}
                    onChange={(event) => {
                        let _fone = maskCelular(event.target.value);
                        setCelular(_fone);
                    }}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.celular.validate = true;
                        _error.celular.message = "";
                        setError(_error);
                    }}
                    error={!error.celular.validate}
                    helperText={error.celular.message}
                    color="primary"
                    id="celular"
                    name="celular"
                    label="Celular"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu celular"
                    fullWidth
                />

                <TextField
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.email.validate = true;
                        _error.email.message = "";
                        setError(_error);
                    }}
                    error={!error.email.validate}
                    helperText={error.email.message}
                    color="primary"
                    id="email"
                    name="email"
                    label="E-mail"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu e-mail"
                    fullWidth
                />
            </ThemeProvider>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.mt20}} onClick={handleSubmitForm}>
                    Salvar
                </Button>
            </div>
        </div>
    );
}

export default StepContactInformation