import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Typography } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import IconButton from '@material-ui/core/IconButton';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AlertModal from "../../components/views/AlertModal";
import { RequestTaxPayerCouponsGroupinvoices } from "../../components/infrastructures/UrlEndpoints";

const ListInvoicesTaxPayer = () => {
    let navigate = useNavigate();

    const { hash } = useParams();

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [list, setList] = useState([]);
    const [name, setName] = useState("");
    const [modalTaxPayerNotFound, setModalTaxPayerNotFound] = useState(false);
    const [messageTaxPayerNotFound, setMessageTaxPayerNotFound] = useState("Contribuinte não encontrado");
    const [keyTaxPayerNotFound, setKeyTaxPayerNotFound] = useState(Math.random());

    useEffect(() => {
        async function processResquetAPI() {
            try {
                let _params = JSON.parse(atob(hash));
                if (typeof _params.UUID !== "undefined") {
                    setName(_params.Name)
                    getTaxPayerCouponsGroupinvoices(_params.UUID);
                } else {
                    setModalTaxPayerNotFound(true);
                    setKeyTaxPayerNotFound(Math.random())
                }
            } catch (error) {
                setModalTaxPayerNotFound(true);
                setKeyTaxPayerNotFound(Math.random())
            }
        }

        processResquetAPI()
    }, [hash]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getTaxPayerCouponsGroupinvoices = (uuid) => {
        _setLoading(true);

        Http({
            method: "GET",
            url: RequestTaxPayerCouponsGroupinvoices(uuid),
        }).then(response => {
            _setLoading(false)
            let _list = response.data.map(item => {
                item.Option = getButtons(item);
                return item;
            });
            setList(_list);
        }).catch(err => {
            _setLoading(false)
            alert("Falha durante o processo. Entre em contato com o Administrador. [IMP.01]");
            setList([]);
        });
    }

    const handleCoupons = (event, item) => {
        event.preventDefault();
        let hash = btoa(JSON.stringify(item));
        navigate(`/lista-certificados-reimpressao/${hash}`);
    }

    const handleCloseTaxPayerNotFound = () => {
        setModalTaxPayerNotFound(true);
        setKeyTaxPayerNotFound(Math.random());
        navigate(`/sistema`);
    }

    const getButtons = (item) => {
        return (
            <>
                <IconButton aria-label="delete" onClick={(event) => handleCoupons(event, item)}>
                    <ReceiptIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
            </>
        );
    }

    const columns = [
        {
            id: 'Option',
            label: 'Opções'
        },
        {
            id: 'Date',
            label: 'Data',
            align: 'left'
        },
        {
            id: 'Certificate',
            label: 'Certificados',
            align: 'left'
        }
    ];

    return (
        <div style={{width: "900px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <AlertModal
                open={modalTaxPayerNotFound}
                handleClose={() => handleCloseTaxPayerNotFound()}
                message={messageTaxPayerNotFound}
                title="Mensagem"
                key={keyTaxPayerNotFound}
            />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Grupo de trocas de notas do contribuinte: {name}</Typography>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={list}/>
            </div>
        </div>
    );
};

export default ListInvoicesTaxPayer;