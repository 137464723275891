import React, { useState } from 'react';
import { Button, TextField, Select } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Sortable from '../../../components/views/Sortable';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const StepTwo = (props) => {

    const classes = useStyles();

    const [award, setAward] = useState("");
    const [special, setSpecial] = useState("n");
    const [awards, setAwards] = useState([]);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [keyItens, setKeyItens] = useState(Math.random());

    let keyData = props.keyData;

    const [error, setError] = useState({
        award: {validate: true, message: null},
        special: {validate: true, message: null}
    });

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (award === null || award.trim() === "") {
            _error.award.validate = false;
            _error.award.message = "Por favor, insira o prêmio que será sorteado.";
            _verify = true;
        }

        if (special === "") {
            _error.special.validate = false;
            _error.special.message = "Por favor, informe se o prêmio é para uma rodada especial.";
            _verify = true;
        }

        setError(_error);
        return _verify;
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (awards.length === 0) return;

        props.setPreviewData({
            Awards: awards
        });

        props.setStep(2);
    }

    const cleanSpecialMessageError = () => {
        let _error = {...error};
        _error.special.validate = true;
        _error.special.message = "";
        setError(_error);
    }

    const handleAddAward = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        let _awards = [...awards];
        _awards.push({award, special});
        setAwards(_awards);
        setAward("");
        setSpecial("n");
        setKeyLoading(Math.random());
        setKeyItens(Math.random());
    }

    const onSortEnd = (itens) => {
        setAwards(itens);
        setKeyItens(Math.random());
    }

    return (
        <div key={props.keyData} style={{width: props.width, margin: "auto", marginTop: "40px"}}>
            <ThemeProvider theme={Theme} key={keyLoading}>
                <div style={{fontSize: "12px", color: "gray", fontWeight: "bold", paddingBottom: "20px"}}>
                    ** Casdastro de prêmios deverá ser feito por order de importância!!!
                </div>

                <div style={{display: "grid", gridTemplateColumns: "49% 34% 15%", gridGap: "2%"}}>
                    <div>
                        <TextField
                            value={award}
                            onChange={(event) => setAward(event.target.value)}
                            onFocus={(event) => {
                                let _error = {...error};
                                _error.award.validate = true;
                                _error.award.message = "";
                                setError(_error);
                            }}
                            style={{paddingTop: "3px"}}
                            error={!error.award.validate}
                            helperText={error.award.message}
                            color="primary"
                            id="award"
                            name="award"
                            label="Prêmio"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite o prêmio que será sorteado"
                            fullWidth
                        />
                    </div>

                    <div>
                        <FormControl className={classes.formControl} error={!error.special.validate}>
                            <InputLabel id="demo-simple-select-label">Especial</InputLabel>
                            <Select
                                native
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={special}
                                onChange={(event) => {
                                    setSpecial(event.target.value);
                                    cleanSpecialMessageError();
                                }}
                            >
                                <option value="s">Sim</option>
                                <option value="n">Não</option>
                            </Select>
                            <FormHelperText>{error.special.message}</FormHelperText>
                        </FormControl>
                    </div>

                    <div>
                        <Button style={{...Theme.palette.buttonRed, width: "100%"}} onClick={handleAddAward}>
                            Adicionar
                        </Button>
                    </div>
                </div>
            </ThemeProvider>

            <div>
                <Sortable itens={awards} key={keyItens} onSortEnd={onSortEnd}/>
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.mt20}} onClick={handleSubmitForm}>
                    Próximo
                </Button>
            </div>
        </div>
    );
}

export default StepTwo;
