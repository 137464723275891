import axios from "axios";
import { getToken } from "./Auth";
import { getUrl } from "./Environment";

let url = getUrl();

const Http = axios.create({
    baseURL: url
});

Http.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

Http.getRequest = (id, url, data) => {

    let request = {
        method: `POST`,
        url: url,
        data: data
    }

    if (id) {
        request.method = `PUT`;
        //request.url = `/${url}/${id}`;
    }

    return request;
}

Http.getPrintUrl = () => {
    return "http://print.novasantarita.rs.gov.br"
}

export default Http;
