import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Button, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../components/views/Theme';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { RequestTaxpayerCouponsByUUID } from "../../components/infrastructures/UrlEndpoints";

const CancelCoupons = () => {
    let navigate = useNavigate();

    const { hash } = useParams();
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [coupon, setCoupon] = useState("");
    const [coupons, setCoupons] = useState([]);
    const [couponsSearch, setCouponsSearch] = useState([]);

    useEffect(() => {
        async function processResquetAPI() {
            try {
                let _params = JSON.parse(atob(hash));
                if (typeof _params.UUID !== "undefined") {
                    getTaxpayerCouponsByUUID(_params.UUID)
                } else {
                    alert("Falha durante o processo. Entre em contato com o Administrador. [CAN.01]");
                }
            } catch (error) {
                alert("Falha durante o processo. Entre em contato com o Administrador. [CAN.02]");
            }
        }

        processResquetAPI()
    }, [hash]);

    const getTaxpayerCouponsByUUID = (uuid) => {
        Http({
            method: "GET",
            url: RequestTaxpayerCouponsByUUID(uuid)
        }).then(response => {
            let _coupons = response.data.map(item => {
                return {
                    Option: getButtons(item),
                    Certificate: item.Certificate
                }
            });
            setCouponsSearch(_coupons);
            setCoupons(_coupons);
        }).catch(err => {
            setCouponsSearch([]);
            setCoupons([]);
        });
    }

    const handleDeleteCertificate = (event, item) => {
        event.preventDefault();
        console.log('handleDeleteCertificate >> item >> ', item);
        let hash = btoa(JSON.stringify(item));
        navigate(`/lista-certificados-notas-cancelar/${hash}`);

    }

    const handleSearch = (event) => {
        event.preventDefault();
        let _list = [...couponsSearch];
        if (coupon.trim() !== "") {
            _list = _list.filter(item => item.Certificate.toUpperCase().indexOf(coupon.trim().toUpperCase()) >= 0);
        }

        setCoupons(_list);
    }

    const handleClearSearch = (event) => {
        event.preventDefault();
        setCoupon("");
        let _list = [...couponsSearch];
        setCouponsSearch(_list);
        setCoupons(_list);
    }

    const getButtons = (item) => {
        return (
            <>
                <IconButton aria-label="delete" onClick={(event) => handleDeleteCertificate(event, item)}>
                    <DeleteIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
            </>
        );
    }

    const columns = [
        {
            id: 'Option',
            label: 'Opções'
        },
        {
            id: 'Certificate',
            label: 'Certificados',
            align: 'right'
        }
    ];

    return (
        <div style={{width: "900px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Certificados do contribuinte: </Typography>
            </div>

            <div style={{display: "grid", gridGap: "5%", gridTemplateColumns: "60% 15% 15%", marginBottom: "15px"}}>
                <div>
                    <ThemeProvider theme={Theme}>
                        <TextField
                            value={coupon}
                            onChange={(event) => setCoupon(event.target.value)}
                            color="primary"
                            id="coupon"
                            name="coupon"
                            label="Certificado do contribuinte"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite o certificado do contribuinte"
                            fullWidth
                        />
                    </ThemeProvider>
                </div>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button style={{...Theme.palette.buttonRed, padding:"0px", width: "100%"}} onClick={(event) => handleSearch(event)}>
                        Buscar
                    </Button>
                </div>

                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Button style={{...Theme.palette.buttonWhite, padding:"0px", width: "100%"}} onClick={(event) => handleClearSearch(event)}>
                        Limpar
                    </Button>
                </div>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={coupons}/>
            </div>
        </div>
    );
};

export default CancelCoupons;