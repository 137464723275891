import React, { useState, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import StepOne from "./register-award-steps/StepOne";
import StepTwo from "./register-award-steps/StepTwo";
import StepThree from "./register-award-steps/StepThree";
import TaxPayerStep from "../../components/views/TaxPayerStep";
import Theme from "../../components/views/Theme";
import { formatDateDB, getDateFormat } from "../../components/views/Functions";
import successIcon from "../../images/success-icon.png"
import AlertModal from "../../components/views/AlertModal";
import WarningIcon from '@material-ui/icons/Warning';
import { useNavigate } from 'react-router-dom';
import { RequestAwards } from "../../components/infrastructures/UrlEndpoints";

const RegisterAward = () => {
    let navigate = useNavigate();

    const [data, setData] = useState({});
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [keyData, setKeyData] = useState(Math.random());

    const [modalSuccess, setModalSuccess] = useState(false);
    const [keyModalSuccess, setKeyModalSuccess] = useState(Math.random());

    const [modalError, setModalError] = useState(false);
    const [keyModalError, setKeyModalError] = useState(Math.random());

    const [message, setMessage] = useState([]);

    useEffect(() => {
        setKeyData(Math.random());
    },[data]);

    const send = async (taxPayerId, invoices) => {
        _setLoading(true);

        let _awards = data.Awards.map((item, index) => {
            return {
                Ordenation: index + 1,
                Description: item.award,
                Special: item.special
            };
        });

        let _awardDate = formatDateDB(getDateFormat(data.AwardDate));

        await Http({
            method: "POST",
            url: RequestAwards(),
            data: {
                Date: _awardDate,
                Awards: _awards
            }
        }).then(response => {
            _setLoading(false);
            _setMessageSuccess(true);
        }).catch(err => {
            _setLoading(false);
            // let errors = [];
            // if (Array.isArray(err.response.data)) {
            //     err.response.data.map(item => {
            //         errors.push(item.Message);
            //     });
            // } else {
            //     errors.push("Falha inesperada. Entre em contato com o Administrador.");
            // }

            let message = ["Falha inesperada. Entre em contato com o Administrador."];
            if (Array.isArray(err.response.data)) {
                message = [];
                message.push("Não foi possível atualizar as informações.");
                message.push("Verifique e corriga a informação.");
                message.push("Ao final reenvie os dados.");
            }
            setMessage(message);
            _setMessageError(true);
        });
    }

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const setPreviewData = (info) => {
        let _data = {...data, ...info};
        setData(_data);
    }

    const getSuccessMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <img src={successIcon} className="img-success-icon mb20" alt="Icone sucesso" />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Sorteio cadastrado com sucesso!!!</p>
                </div>

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    const getErrorMessage = () => {
        return (
            <div style={{...Theme.palette.divModalRegisterSuccess}}>
                <div>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                {message.map((item, index) => {
                    return (
                        <div key={index}>
                            <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>{item}</p>
                        </div>
                    )
                })}

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleOk(event)}>
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    const _setMessageSuccess = (option) => {
        setModalSuccess(option);
        setKeyModalSuccess(Math.random());
    }

    const _setMessageError = (option) => {
        setModalError(option);
        setKeyModalError(Math.random());
    }

    const handleOk = (event) => {
        event.preventDefault();
        _setMessageSuccess(false);
        _setMessageError(false);
        navigate(`/sistema`);
    }

    const steps = [
        <StepOne
            setStep={setStep}
            setPreviewData={setPreviewData}
            keyData={keyData}
            data={data}
            width="400px"/>,

        <StepTwo
            setStep={setStep}
            setPreviewData={setPreviewData}
            keyData={keyData}
            data={data}
            width="600px"/>,

        <StepThree
            setStep={setStep}
            send={send}
            keyData={keyData}
            data={data}
            width="800px"/>,
    ];

    return (
        <div>
            <PageLoad open={loading} key={keyLoading} />

            <AlertModal
                open={modalSuccess}
                handleClose={_setMessageSuccess}
                message={null}
                title={null}
                body={getSuccessMessage()}
                key={keyModalSuccess}
            />

            <AlertModal
                open={modalError}
                handleClose={_setMessageError}
                message={null}
                title={null}
                body={getErrorMessage()}
                key={keyModalError}
            />

            <div style={{margin:"auto", paddingTop: "20px", paddingBottom: "20px", width: "800px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Cadastrar sorteio</Typography>
            </div>

            <div style={{margin:"auto", width: "500px"}}>
                <div style={{display: "grid", gridTemplateColumns: "32% 32% 32%", gridGap: "2%"}}>
                    <TaxPayerStep step={1} title="Data do sorteio" active={step === 0} />
                    <TaxPayerStep step={2} title="Prêmios" active={step === 1} />
                    <TaxPayerStep step={3} title="Visualização" active={step === 2} />
                </div>
            </div>

            <div>
                {steps[step]}
            </div>
        </div>
    );
};

export default RegisterAward;