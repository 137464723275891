import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import Theme from "../../../components/views/Theme";
import Cropper from "react-easy-crop"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ThemeProvider } from '@material-ui/styles';
import SelectApplication from "../../../components/views/SelectApplication";
import { getTypeFile, numberToMoney, decode } from "../../../components/views/Functions";
import Http from "../../../components/infrastructures/Http"
import { RequestViewPdf, RequestViewImage, RequestAccessFile } from "../../../components/infrastructures/UrlEndpoints";
//import { getToken } from "../../../components/infrastructures/Auth";
import { getUrl } from "../../../components/infrastructures/Environment";

const StepOne = (props) => {
    let navigate = useNavigate();
    const { hash } = useParams();
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [invoiceUUID, setInvoiceUUID] = useState(null);
    const [coupons, setCoupons] = useState([]);

    const [image, setImage] = useState("");
    const [pathPdf, setPathPdf] = useState("");
    const [crop, setCrop] = useState({
      x: 0,
      y: 0,
      width: 300,
      height: 600
    });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [inputValue, setInputValue] = useState(1);
    const [typeFile, setTypeFile] = useState("");

    const [messageFile] = useState("Para o documento ser classificada como nítido é necessário que esteja fácil a Identificação dos números e letras. Não pode ser desfocado, tremulo e/ou faltando partes da nota");
    const [messageValue, setMessageValue] = useState("Valor digitado pelo contribuinte R$ ");
    const [messageDocument, setMessageDocument] = useState("Número digitado pelo contribuintes");
    const [messageCpf, setMessageCpf] = useState("Cpf do contribuinte");

    const [optionImage, setOptionImage] = useState(null);
    const [optionValue, setOptionValue] = useState(null);
    const [optionDocument, setOptionDocument] = useState(null);
    const [optionCpf, setOptionCpf] = useState(null);
    const [optionType, setOptionType] = useState(null);

    const [placeProduct, setPlaceProduct] = useState("Mercado");
    const [placeService, setPlaceService] = useState("Gerais");

    const [visibleRequiredImage, setVisibleRequiredImage] = useState(false);
    const [visibleRequiredValue, setVisibleRequiredIValue] = useState(false);
    const [visibleRequiredDocument, setVisibleRequiredDocument] = useState(false);
    const [visibleRequiredCpf, setVisibleRequiredCpf] = useState(false);
    const [visibleRequiredType, setVisibleRequiredType] = useState(false);

    const [productSelect] = useState([
        "Mercado", "Farmácia", "Vestuário", "Combustível", "Móveis", "Eletrodomésticos", "Outros"
    ]);

    const [serviceSelect] = useState([
        "Gerais"
    ]);

    useEffect(() => {
        async function processResquetAPI() {
            try {
                let _params = JSON.parse(decode(hash));
                setInvoiceUUID(_params.UUID);
                setTypeFile(getTypeFile(_params.FileName));
                setMessageValue("Valor digitado pelo contribuinte R$ " + numberToMoney(_params.Value));
                setMessageDocument("Número digitado pelo contribuintes " + _params.Document);
                setMessageCpf("Cpf do contribuintes " + (_params.Cpf || ""));
                getAccessFile(_params);
            } catch (error) {
                navigate("/notas-em-analise")
            }
        }

        processResquetAPI()
    }, [hash]);

    const getAccessFile = async (_params) => {
        let rs = await Http({
            method: "GET",
            url: RequestAccessFile()
        }).then(response => {
            setImage(getUrl() + "" + RequestViewImage(_params.FileName, response.data));
            setPathPdf(getUrl() + "" + RequestViewPdf(_params.FileName, response.data));
        }).catch(err => {
            console.log("err", err);
        });
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        return false;
    }, [])

    const onZoomChange = (zoom) => {}

    const handleZoomLess = (event) => {
        event.preventDefault();
        if (inputValue > 1) {
          let _zoom = zoom;
          _zoom = parseFloat(zoom - 0.1).toFixed(1);
          setZoom(parseFloat(_zoom));
          setInputValue(inputValue - 1);
        }
    }

    const handleZoomMore = (event) => {
        event.preventDefault();
        if (inputValue < 20) {
          let _zoom = zoom;
          _zoom = parseFloat(zoom + 0.1).toFixed(1);
          setZoom(parseFloat(_zoom));
          setInputValue(inputValue + 1);
        }
    }

    const onChangeFile = e => {
        setOptionImage(e.target.value);
        setVisibleRequiredImage(false);
    };

    const onChangeValue = e => {
        setOptionValue(e.target.value);
        setVisibleRequiredIValue(false);
    };

    const onChangeDocument = e => {
        setOptionDocument(e.target.value);
        setVisibleRequiredDocument(false);
    };

    const onChangeCpf = e => {
        setOptionCpf(e.target.value);
        setVisibleRequiredCpf(false);
    };

    const onChangeType = e => {
        setOptionType(e.target.value);
        setVisibleRequiredType(false);
    };

    const handleOpenFileNewTab = (event) => {
        event.preventDefault();
        window.open(image, '_blank').focus();
    }

    const validateForm = () => {
        let verify = false;
        if (optionImage === null) {
            setVisibleRequiredImage(true);
            verify = true;
        }

        if (optionValue === null) {
            setVisibleRequiredIValue(true);
            verify = true;
        }

        if (optionDocument === null) {
            setVisibleRequiredDocument(true);
            verify = true;
        }

        if (optionType === null) {
            setVisibleRequiredType(true);
            verify = true;
        }

        return verify;
    }

    const handleSave = async (event) => {
        event.preventDefault();

        if (validateForm()) return;

        let _messageFile = (optionImage === "n") ? messageFile : "";
        let _messageValue = (optionValue === "n") ? messageValue : "";
        let _messageDocument = (optionDocument === "n") ? messageDocument : "";
        let _messageCpf = (optionCpf === "n") ? messageCpf : "";
        let _place = (optionType === "p") ? placeProduct : placeService;

        let data = {
            UUID: invoiceUUID,
            MessageFile: _messageFile,
            MessageValue: _messageValue,
            MessageDocument: _messageDocument,
            MessageCpf: _messageCpf,
            Type: optionType,
            Place: _place
        }

        try {
            let rs = await props.send(data);
            if (rs.status) {
                props.setStep(1);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    return (
        <div style={{width: "800px", margin: "auto"}}>
            <div style={{display: "grid", gridGap: "20px", gridTemplateColumns: "390px 390px"}}>
                {(typeFile === "PDF") && (
                    <div>
                        <iframe style={{width: "390px", height: "400px"}} src={pathPdf} title="description"></iframe>
                        <div>
                            <Button style={{...Theme.palette.btnRedRegisterInvoice}} onClick={(event) => handleOpenFileNewTab(event)}>
                                Abrir pdf em outra aba do navegador
                            </Button>
                        </div>
                    </div>
                )}

                {(typeFile !== "PDF") && (
                    <div>
                        <div>
                            <Cropper
                                image={image}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                showGrid={false}
                                aspect={1 / 2}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={onZoomChange}
                                style={{
                                    cropAreaStyle : {
                                        background: "transparent",
                                        border: "2px solid #ED3136",
                                        borderRadius: "10px",
                                        color: "#f7f7f7",
                                        minWidth: "390px",
                                        minHeight: "400px"
                                    },
                                    containerStyle : {
                                        background: "#fff",
                                        height: "400px",
                                        width: "390px",
                                        position: "relative"
                                    }
                                }}
                            />
                        </div>
                        <div style={{display: "grid", gridGap: "2%", gridTemplateColumns: "30% 36% 30%", marginTop: "15px"}}>
                            <div>
                                <Button style={{...Theme.palette.btnRedRegisterInvoice}} onClick={(event) => handleZoomLess(event)}>
                                    -
                                </Button>
                            </div>
                            <div style={{display: "flex", justifyContent:"center", alignItems:"center"}}>{inputValue}</div>
                            <div>
                                <Button style={{...Theme.palette.btnRedRegisterInvoice}} onClick={(event) => handleZoomMore(event)}>
                                    +
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                <div>
                    <div>
                        <p style={Theme.palette.textTitle}>1 - O documento está nítido?</p>
                        <div>
                            <ThemeProvider theme={Theme}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" value={optionImage} onChange={onChangeFile}>
                                        <FormControlLabel value="s" control={<Radio color="primary" />} label="Sim" />
                                        <FormControlLabel value="n" control={<Radio color="primary" />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </ThemeProvider>
                        </div>

                        <p style={Theme.palette.textDescription}>Obs: {messageFile}</p>
                        <p style={{...Theme.palette.textError, display: (visibleRequiredImage) ? "block" : "none"}}>Campo é obrigatório</p>
                    </div>

                    <div style={Theme.palette.mt20}>
                        <p style={Theme.palette.textTitle}>2 - O valor digitado pelo contribuinte está igual ao da nota fiscal?</p>
                        <div>
                            <ThemeProvider theme={Theme}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" onChange={onChangeValue} value={optionValue}>
                                        <FormControlLabel value="s" control={<Radio color="primary" />} label="Sim" />
                                        <FormControlLabel value="n" control={<Radio color="primary" />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </ThemeProvider>
                        </div>
                        <p style={Theme.palette.textDescription}>Obs: {messageValue}</p>
                        <p style={{...Theme.palette.textError, display: (visibleRequiredValue) ? "block" : "none"}}>Campo é obrigatório</p>
                    </div>

                    <div style={Theme.palette.mt20}>
                        <p style={Theme.palette.textTitle}>3 - O número da nota fiscal digitado pelo contribuinte está igual ao da nota?</p>
                        <div>
                            <ThemeProvider theme={Theme}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" onChange={onChangeDocument} value={optionDocument}>
                                        <FormControlLabel value="s" control={<Radio color="primary" />} label="Sim" />
                                        <FormControlLabel value="n" control={<Radio color="primary" />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </ThemeProvider>
                        </div>
                        <p style={Theme.palette.textDescription}>Obs: {messageDocument}</p>
                        <p style={{...Theme.palette.textError, display: (visibleRequiredDocument) ? "block" : "none"}}>Campo é obrigatório</p>
                    </div>

                    <div style={Theme.palette.mt20}>
                        <p style={Theme.palette.textTitle}>4 - O CPF que consta nota fiscal é o mesmo do contribuinte?</p>
                        <div>
                            <ThemeProvider theme={Theme}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" onChange={onChangeCpf} value={optionCpf}>
                                        <FormControlLabel value="s" control={<Radio color="primary" />} label="Sim" />
                                        <FormControlLabel value="n" control={<Radio color="primary" />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </ThemeProvider>
                        </div>
                        <p style={Theme.palette.textDescription}>Obs: {messageCpf}</p>
                        <p style={{...Theme.palette.textError, display: (visibleRequiredCpf) ? "block" : "none"}}>Campo é obrigatório</p>
                    </div>

                    <div style={Theme.palette.mt20}>
                        <p style={Theme.palette.textTitle}>5 - Tipo da nota?</p>
                        <div>
                            <ThemeProvider theme={Theme}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" onChange={onChangeType} value={optionType}>
                                        <FormControlLabel value="p" control={<Radio color="primary" />} label="Produto" />
                                        <FormControlLabel value="s" control={<Radio color="primary" />} label="Serviço" />
                                    </RadioGroup>
                                </FormControl>
                            </ThemeProvider>
                        </div>
                        <p style={{...Theme.palette.textError, display: (visibleRequiredType) ? "block" : "none"}}>Campo é obrigatório</p>
                    </div>

                    <div style={Theme.palette.mt20}>
                        <p style={Theme.palette.textTitle}>6 - Localização?</p>
                        <div style={{display: (optionType === "p") ? "block" : "none"}}>
                            <ThemeProvider theme={Theme}>
                                <SelectApplication
                                    label="Local"
                                    error={{validate: true, message: null}}
                                    value={placeProduct}
                                    onChange={setPlaceProduct}>
                                    {productSelect.map((item, index) => {
                                        return <option value={item} key={index}>{item}</option>
                                    })}
                                </SelectApplication>
                            </ThemeProvider>
                        </div>

                        <div style={{display: (optionType === "s") ? "block" : "none"}}>
                            <ThemeProvider theme={Theme}>
                                <SelectApplication
                                    label="Local"
                                    error={{validate: true, message: null}}
                                    value={placeService}
                                    onChange={setPlaceService}>
                                    {serviceSelect.map((item, index) => {
                                        return <option value={item} key={index}>{item}</option>
                                    })}
                                </SelectApplication>
                            </ThemeProvider>
                        </div>
                        <p style={{...Theme.palette.textError, display: (visibleRequiredType) ? "block" : "none"}}>Campo é obrigatório</p>
                    </div>
                </div>
            </div>

            <div style={{display: "grid", gridGap: "5%", gridTemplateColumns: "30% 30% 30%", margin: "30px 0px"}}>
                <div></div>
                <div>
                    <Button style={{...Theme.palette.btnRedRegisterInvoice}} onClick={(event) => handleSave(event)}>
                        Salvar
                    </Button>
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default StepOne;