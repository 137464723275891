import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Theme from '../../components/views/Theme';
import logo from "../../images/nsr-premiada.png";
import AlertModal from "../../components/views/AlertModal";
import PageLoad from "../../components/views/PageLoad";
import Http from "../../components/infrastructures/Http";
import { RequestLogin } from "../../components/infrastructures/UrlEndpoints";
import { login as loginApplication, getToken } from "../../components/infrastructures/Auth";
import { useApplicationContext } from '../../components/infrastructures/Context';
import { changeUserName } from "../../components/infrastructures/Reducer";

const Login = () => {
    let navigate = useNavigate();

    useEffect(() => {
        let token = getToken();
        if (token !== null && token !== "") {
            navigate("/sistema");
        }
    },[]);

    const [application, dispatch] = useApplicationContext();

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());

    const [modalError, setModalError] = useState(false);
    const [keyModalError, setKeyModalError] = useState(Math.random());

    const [messageError] = useState([
        "Falha inesperada.",
        "Entre em contato com o administrador."
    ]);

    const [open, setOpen] = useState(false);
    const [message] = useState([
        "Entre em contato com a secretaria - SMICD pelo telefone:",
        "(51) 3479-1426 ou pelo whatsapp 51 99299-6131",
        "E solicite uma nova senha."
    ]);

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState({
        login: {validate: true, message: null},
        password: {validate: true, message: null},
    });

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const _setMessageError = (option) => {
        setModalError(option);
        setKeyModalError(Math.random());
    }

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (login === "") {
            _error.login.validate = false;
            _error.login.message = "Por favor, insira seu login.";
            _verify = true;
        }

        if (password === "") {
            _error.password.validate = false;
            _error.password.message = "Por favor, insira sua senha.";
            _verify = true;
        }

        setError(_error);
        return _verify;
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        _setLoading(true);

        Http({
            method: "POST",
            url: RequestLogin(),
            data: {
                Login: login,
                Password: password,
            }
        }).then(response => {
            _setLoading(false);
            if (response.status === 200) {
                // dispatch(changeUserName(response.data.Name));
                // loginApplication(response.data.Token);
                dispatch(changeUserName("Administrador"));
                loginApplication(response.data.access_token);
                navigate("/sistema");
            } else {
                _setMessageError(true);
            }
        }).catch(err => {
            _setLoading(false);
            let _error = {...error};
            if (Array.isArray(err.response.data)) {
                err.response.data.map(item => {
                    _error[item.Field.toLowerCase()] = {
                        validate: false,
                        message: item.Message
                    }
                });
                setError(_error);
            } else {
                _setMessageError(true);
            }
        });

    }

    const handleForgetPassword = (event) => {
        event.preventDefault();
        setOpen(true);
    }

    const handleClose = (event) => {
        setOpen(false);
    }

    return (
        <div style={Theme.palette.divPageLogin}>
            <form>
                <PageLoad open={loading} key={keyLoading} />

                <AlertModal
                    open={open}
                    handleClose={handleClose}
                    message={message}
                    title="Esqueci minha senha"
                    key={open}
                />

                <AlertModal
                    open={modalError}
                    handleClose={_setMessageError}
                    message={messageError}
                    title="Erro"
                    key={keyModalError}
                />

                <div style={Theme.palette.divCenter}>
                    <img src={logo} style={Theme.palette.imgPageLogin} alt="Nova Santa Rita Premiada" />
                </div>

                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Login</Typography>

                <ThemeProvider theme={Theme}>
                    <TextField
                        value={login}
                        onChange={(event) => setLogin(event.target.value)}
                        onFocus={(event) => {
                            let _error = {...error};
                            _error.login.validate = true;
                            _error.login.message = "";
                            setError(_error);
                        }}
                        error={!error.login.validate}
                        helperText={error.login.message}
                        color="primary"
                        id="login"
                        name="login"
                        label="Login"
                        size="small"
                        margin="dense"
                        type="text"
                        placeholder="Digite seu login"
                        fullWidth
                    />

                    <TextField
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onFocus={(event) => {
                            let _error = {...error};
                            _error.password.validate = true;
                            _error.password.message = "";
                            setError(_error);
                        }}
                        error={!error.password.validate}
                        helperText={error.password.message}
                        color="primary"
                        id="password"
                        name="password"
                        label="Senha"
                        size="small"
                        margin="dense"
                        type="password"
                        placeholder="Digite sua senha"
                        fullWidth
                    />

                </ThemeProvider>

                <div style={{...Theme.palette.divSpaceLink}}>
                    <a style={{...Theme.palette.link}} onClick={(event) => handleForgetPassword(event)}>Esqueci minha senha</a>
                </div>

                <div style={{...Theme.palette.divCenter}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={handleSubmitForm}>
                        Entrar
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Login;