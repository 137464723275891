import React, { useState, useEffect } from 'react';
import { Button, TextField, Select } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import { ThemeProvider } from '@material-ui/styles';
import { maskZipCode } from "../../../components/views/Masks";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const StepAddressInformation = (props) => {

    const classes = useStyles();

    let countries = props.countries;
    let states = props.states;
    let cities = props.cities;

    const [addressType, setAddressType] = useState("rua");
    const [address, setAddress] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [district, setDistrict] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [countryId, setCountryId] = useState(1);
    const [stateId, setStateId] = useState(23);
    const [cityId, setCityId] = useState(4134);

    const [zipCodeSelected, setZipCodeSelected] = useState(false);

    const [error, setError] = useState({
        addressType: {validate: true, message: null},
        address: {validate: true, message: null},
        number: {validate: true, message: null},
        complement: {validate: true, message: null},
        district: {validate: true, message: null},
        zipCode: {validate: true, message: null},
        country: {validate: true, message: null},
        state: {validate: true, message: null},
        city: {validate: true, message: null}
    });

    let keyData = props.keyData;

    useEffect(() => {
        setAddressType(props.data.AddressType || "rua");
        setAddress(props.data.Address || "");
        setNumber(props.data.Number || "");
        setComplement(props.data.Complement || "");
        setDistrict(props.data.District || "");
        setZipCode(props.data.ZipCode || "");
        setCountryId(props.data.CountryId || 1);
        setStateId(props.data.StateId || 23);
        setCityId(props.data.CityId || 4134);
    }, [keyData]);

    const handleSubmitForm = (event) => {
        event.preventDefault();

        props.setPreviewData({
            AddressType: addressType,
            Address: address,
            Number: number,
            Complement: complement,
            District: district,
            ZipCode: zipCode,
            CountryId: countryId,
            StateId: stateId,
            CityId: cityId
        });

        props.setStep(2);
    }

    return (
        <div key={props.keyData}>
            <ThemeProvider theme={Theme}>
                <FormControl className={classes.formControl} error={!error.addressType.validate}>
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select
                        native
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={addressType}
                        onChange={(event) => setAddressType(event.target.value)}
                    >
                        <option value="rua">rua</option>
                        <option value="avenida">avenida</option>
                        <option value="aeroporto">aeroporto</option>
                        <option value="alameda">alameda</option>
                        <option value="área">área</option>
                        <option value="campo">campo</option>
                        <option value="chácara">chácara</option>
                        <option value="colônia">colônia</option>
                        <option value="condomínio">condomínio</option>
                        <option value="conjunto">conjunto</option>
                        <option value="distrito">distrito</option>
                        <option value="esplanada">esplanada</option>
                        <option value="estação">estação</option>
                        <option value="estrada">estrada</option>
                        <option value="favela">favela</option>
                        <option value="fazenda">fazenda</option>
                        <option value="feira">feira</option>
                        <option value="jardim">jardim</option>
                        <option value="ladeira">ladeira</option>
                        <option value="lago">lago</option>
                        <option value="lagoa">lagoa</option>
                        <option value="largo">largo</option>
                        <option value="loteamento">loteamento</option>
                        <option value="morro">morro</option>
                        <option value="núcleo">núcleo</option>
                        <option value="parque">parque</option>
                        <option value="passarela">passarela</option>
                        <option value="pátio">pátio</option>
                        <option value="praça">praça</option>
                        <option value="quadra">quadra</option>
                        <option value="recanto">recanto</option>
                        <option value="residencial">residencial</option>
                        <option value="rodovia">rodovia</option>
                        <option value="setor">setor</option>
                        <option value="sítio">sítio</option>
                        <option value="travessa">travessa</option>
                        <option value="trecho">trecho</option>
                        <option value="trevo">trevo</option>
                        <option value="vale">vale</option>
                        <option value="vereda">vereda</option>
                        <option value="via">via</option>
                        <option value="viaduto">viaduto</option>
                        <option value="viela">viela</option>
                        <option value="vila">vila</option>
                    </Select>
                    <FormHelperText>{error.addressType.message}</FormHelperText>
                </FormControl>

                <TextField
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.address.validate = true;
                        _error.address.message = "";
                        setError(_error);
                    }}
                    error={!error.address.validate}
                    helperText={error.address.message}
                    color="primary"
                    id="address"
                    name="address"
                    label="Endereço"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu endereço"
                    fullWidth
                />

                <TextField
                    value={number}
                    onChange={(event) => setNumber(event.target.value)}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.number.validate = true;
                        _error.number.message = "";
                        setError(_error);
                    }}
                    error={!error.number.validate}
                    helperText={error.number.message}
                    color="primary"
                    id="number"
                    name="number"
                    label="Número"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu número"
                    fullWidth
                />

                <TextField
                    value={complement}
                    onChange={(event) => setComplement(event.target.value)}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.complement.validate = true;
                        _error.complement.message = "";
                        setError(_error);
                    }}
                    color="primary"
                    id="complement"
                    name="complement"
                    label="Complemento"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite o complemento"
                    fullWidth
                />

                <TextField
                    value={district}
                    onChange={(event) => setDistrict(event.target.value)}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.district.validate = true;
                        _error.district.message = "";
                        setError(_error);
                    }}
                    color="primary"
                    id="district"
                    name="district"
                    label="Bairro"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite o bairro"
                    fullWidth
                />

                <TextField
                    value={zipCode}
                    // onSelect={(event) => {
                    //     setZipCodeSelected(event.target.selectionStart === 0 && event.target.selectionEnd === 9);
                    // }}
                    // onKeyDown={(event) => {
                    //     if (zipCodeSelected) setZipCode("");
                    // }}
                    // onKeyUp={(event) => {
                    //     event.preventDefault();
                    //     let _zipCode = maskZipCode(zipCode, event.key);
                    //     setZipCode(_zipCode);
                    // }}
                    // onBlur={(event) => {
                    //     if (zipCode === "_____-___") {
                    //         setZipCode("");
                    //     }
                    // }}
                    onChange={(event) => {
                        let _zipCode = maskZipCode(event.target.value);
                        setZipCode(_zipCode);
                    }}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.zipCode.validate = true;
                        _error.zipCode.message = "";
                        setError(_error);
                    }}
                    error={!error.zipCode.validate}
                    helperText={error.zipCode.message}
                    color="primary"
                    id="zipCode"
                    name="zipCode"
                    label="CEP"
                    size="small"
                    margin="dense"
                    type="text"
                    placeholder="Digite seu CEP"
                    fullWidth
                />

                <FormControl className={classes.formControl} error={!error.country.validate}>
                    <InputLabel id="demo-simple-select-label">País</InputLabel>
                    <Select
                        native
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={countryId}
                        onChange={(event) => setCountryId(event.target.value)}
                    >
                        {countries.map((item, index) => {
                            return <option key={index} value={item.Id}>{item.Name}</option>
                        })}
                    </Select>
                    <FormHelperText>{error.country.message}</FormHelperText>
                </FormControl>

                <FormControl className={classes.formControl} error={!error.state.validate}>
                    <InputLabel id="demo-simple-select-label">UF</InputLabel>
                    <Select
                        native
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={stateId}
                        onChange={(event) => {
                            setStateId(parseInt(event.target.value))
                            setCityId("")
                        }}
                    >
                        {states.filter(item => item.CountryId === parseInt(countryId)).map((item, index) => {
                            return <option key={index} value={item.Id}>{item.Name}</option>
                        })}
                    </Select>
                    <FormHelperText>{error.state.message}</FormHelperText>
                </FormControl>

                <FormControl className={classes.formControl} error={!error.city.validate}>
                    <InputLabel id="demo-simple-select-label">Cidade</InputLabel>
                    <Select
                        native
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={cityId}
                        onChange={(event) => setCityId(parseInt(event.target.value))}
                    >
                        {cities.filter(item => item.StateId === parseInt(stateId)).map((item, index) => {
                            return <option key={index} value={item.Id}>{item.Name}</option>
                        })}
                    </Select>
                    <FormHelperText>{error.city.message}</FormHelperText>
                </FormControl>
            </ThemeProvider>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.mt20}} onClick={handleSubmitForm}>
                    Próximo
                </Button>
            </div>
        </div>
    );
}

export default StepAddressInformation;
