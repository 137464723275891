import React, { useState, useEffect } from "react";
import AlertModal from "../../../components/views/AlertModal";
import AbaRegisterInvoices from "../../../components/views/AbaRegisterInvoices";
import AbaHeaderRegisterInvoices from "../../../components/views/AbaHeaderRegisterInvoices";
import CardRegisterInvoice from "../../../components/views/CardRegisterInvoice";
import HeaderRegisterInvoices from "../../../components/views/HeaderRegisterInvoices";
import { moneyToNumber, numberToMoney } from "../../../components/views/Functions";
import { v4 as uuidv4 } from 'uuid';
import Http from "../../../components/infrastructures/Http";
import { RequestBalaceTaxPayer, RequestBalaceReprocessing } from "../../../components/infrastructures/UrlEndpoints";

const StepThree = (props) => {

    const [name, setName] = useState("");
    const [taxPayerUUID, setTaxPayerUUID] = useState("");
    const [reprocessing, setReprocessing] = useState("");

    const [product, setProduct] = useState(true);
    const [service, setService] = useState(false);

    const [products, setProducts] = useState([]);
    const [services, setServices] = useState([]);

    const [productTotal, setProductTotal] = useState(0);
    const [serviceTotal, setServiceTotal] = useState(0);

    const [productBalance, setProductBalance] = useState(0);
    const [serviceBalance, setServiceBalance] = useState(0);

    const [invoicesTotal, setInvoicesTotal] = useState(0);
    const [certificatesTotal, setCertificatesTotal] = useState(0);

    const [productSelect] = useState([
        "Mercado", "Farmácia", "Vestuário", "Combustível", "Móveis", "Eletrodomésticos", "Outros"
    ]);

    const [serviceSelect] = useState([
        "Gerais"
    ])

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState([
        "Não há notas cadastradas.",
        "Insira dos valores e reenvie para processamento.",
    ]);

    const setVisibleProduct = () => {
        setProduct(true);
        setService(false)
    }

    const setVisibleService = () => {
        setService(true)
        setProduct(false);
    }

    let keyData = props.keyData;

    useEffect(() => {
        if (typeof props.data.GroupInvoiceUUID != "undefined" && props.data.GroupInvoiceUUID != null) {
            setReprocessing(props.data.GroupInvoiceUUID);
        }

        if (typeof props.data.Invoices != "undefined" && Array.isArray(props.data.Invoices)) {
            let _products = [];
            let _services = []
            props.data.Invoices.map(item => {
                let _id = uuidv4();
                if (item.Type == "p") {
                    _products.push({
                        Id: _id,
                        Value: item.Value,
                        Place: item.Place,
                        Document: item.Document,
                        Type: item.Type
                    });
                } else if (item.Type == "s") {
                    _services.push({
                        Id: _id,
                        Value: item.Value,
                        Place: item.Place,
                        Document: item.Document,
                        Type: item.Type
                    });
                }
            });
            setProducts(_products);
            setServices(_services);
        }

        setName(props.data.Name);
        setTaxPayerUUID(props.data.UUID);
    }, [keyData]);

    useEffect(() => {
        getTaxPayerBalances(taxPayerUUID);
    }, [taxPayerUUID]);

    const getTaxPayerBalances = async (uuid) => {
        if (reprocessing) {
            Http({
                method: "GET",
                url: RequestBalaceReprocessing(reprocessing),
            }).then(response => {
                setProductBalance(parseFloat(response.data.product));
                setServiceBalance(parseFloat(response.data.service));
            }).catch(err => {
                setProductBalance(0);
                setServiceBalance(0);
            });
        } else {
            if (uuid === null || uuid === "") return false;
            Http({
                method: "GET",
                url: RequestBalaceTaxPayer(uuid),
            }).then(response => {
                setProductBalance(parseFloat(response.data.product));
                setServiceBalance(parseFloat(response.data.service));
            }).catch(err => {
                setProductBalance(0);
                setServiceBalance(0);
            });
        }
    }

    useEffect(() => {
        let total = sumValue(products, productBalance);
        setProductTotal(total);
    }, [products]);

    useEffect(() => {
        let total = sumValue(services, serviceBalance);
        setServiceTotal(total);
    }, [services]);

    useEffect(() => {
        updateTotalsAndCertificates();
    }, [productTotal]);

    useEffect(() => {
        updateTotalsAndCertificates();
    }, [serviceTotal]);

    const updateTotalsAndCertificates = () => {
        let total = numberToMoney(productTotal + serviceTotal);
        let certificatesProduct = Math.trunc((productTotal / 100));
        let certificatesService = Math.trunc((serviceTotal / 50));
        setCertificatesTotal(certificatesProduct + certificatesService);
        setInvoicesTotal("R$ " + total);
    }

    const sumValue = (arr, balance) => {
        let total = 0;
        arr.map(item => {
            let value = moneyToNumber(item.Value);
            total += value;
        });
        return total + balance;
    }

    const handleNewInvoiceProduct = async (event) => {
        event.preventDefault();
        let _products = [...products];
        let _id = uuidv4();
        _products.push({
            Id: _id,
            Value: null,
            Place: "Mercado",
            Document: null,
            Type: "p"
        });
        setProducts(_products);
    }

    const changeInfoProduct = (item, index) => {
        let _products = [...products];
        _products[index] = item;
        setProducts(_products);
    }

    const changeInfoService = (item, index) => {
        console.log("Service", item, index);
        let _services = [...services];
        _services[index] = item;
        setServices(_services);
    }

    const handleNewInvoiceService = async (event) => {
        event.preventDefault();
        let _services = [...services];
        let _id = uuidv4();
        _services.push({
            Id: _id,
            Value: null,
            Place: "Gerais",
            Document: null,
            Type: "s"
        });
        setServices(_services);
    }

    const handleDeleteProduct = async (event, index) => {
        event.preventDefault();
        let _products = [...products];
        _products = _products.filter((item, idx) => idx !== index).map(item => item);
        setProducts(_products);
    }

    const handleDeleteService = async (event, index) => {
        event.preventDefault();
        let _services = [...services];
        _services = _services.filter((item, idx) => idx !== index).map(item => item);
        setServices(_services);
    }

    const handleSave = async (event) => {
        event.preventDefault();
        if ((sumValue(products, 0) + sumValue(services, 0)) <= 0) {
            setOpen(true);
            setMessage([
                "Não há notas cadastradas.",
                "Insira dos valores e reenvie para processamento."
            ]);
            return false;
        }

        let invoices = [...products, ...services];
        invoices = invoices.filter(item => moneyToNumber(item.Value) > 0).map(item => {
            item.UUID = item.Id;
            item.Value = moneyToNumber(item.Value);
            item.Document = (item.Document === null || item.Document === "") ? uuidv4() : item.Document;
            delete item.Id;
            return item;
        });

        let rs = await props.send(taxPayerUUID, invoices);
        if (rs.status) {
            props.setStep(3);
        } else {
            setMessage(rs.message);
            setOpen(true);
        }
    }

    const handleClose = (event) => {
        setOpen(false);
    }

    return (
        <div style={{width: props.width, margin: "auto"}}>
            <AlertModal
                open={open}
                handleClose={handleClose}
                message={message}
                title="Atenção"
                key={open}
            />

            <div style={{display: "grid", gridTemplateColumns: "48% 33% 15% ", gridGap: "2%", marginBottom:"10px"}}>
                <HeaderRegisterInvoices title="Contribuinte" content={name}/>
                <HeaderRegisterInvoices title="Valor das notas" content={invoicesTotal}/>
                <HeaderRegisterInvoices title="Certificados" content={certificatesTotal}/>
            </div>

            <div style={{display: "flex"}}>
                <AbaRegisterInvoices
                    title="Comércio"
                    active={product}
                    setVisible={setVisibleProduct} />

                <AbaRegisterInvoices
                    title="Serviço"
                    active={service}
                    setVisible={setVisibleService} />
            </div>

            {(product) && (
                <div style={{backgroundColor: "#fff", padding: "20px", borderRadius: "0px 20px 20px 20px"}}>
                    <AbaHeaderRegisterInvoices
                        handleNewInvoice={handleNewInvoiceProduct}
                        handleSave={handleSave}
                        balance={numberToMoney(productBalance)}
                    />

                    <div style={{marginTop: "20px"}}>
                        {products.map((item, index) => {
                            return <CardRegisterInvoice
                                key={item.Id}
                                id={item.Id}
                                index={index}
                                value={item.Value}
                                place={item.Place}
                                document={item.Document}
                                type={item.Type}
                                options={productSelect}
                                handleDelete={handleDeleteProduct}
                                changeInfo={changeInfoProduct}/>
                        })}
                    </div>
                </div>
            )}

            {(service) && (
                <div style={{backgroundColor: "#fff", padding: "20px", borderRadius: "20px"}}>
                    <AbaHeaderRegisterInvoices
                        handleNewInvoice={handleNewInvoiceService}
                        handleSave={handleSave}
                        balance={numberToMoney(serviceBalance)}
                    />

                    <div style={{marginTop: "20px"}}>
                        {services.map((item, index) => {
                            return <CardRegisterInvoice
                                key={item.Id}
                                index={index}
                                id={item.Id}
                                value={item.Value}
                                place={item.Place}
                                document={item.Document}
                                type={item.Type}
                                options={serviceSelect}
                                handleDelete={handleDeleteService}
                                changeInfo={changeInfoService}/>
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default StepThree;