import React, { useState, useEffect } from 'react';
import Theme from './Theme';
import { TextField } from '@material-ui/core';
import { maskMoney } from './Masks';
import { ThemeProvider } from '@material-ui/styles';
import SelectApplication from './SelectApplication';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { getDate } from './Functions';

const CardRegisterInvoice = (props) => {
    const date = getDate();
    const [id] = useState(props.id || "");
    const [value, setValue] = useState(props.value || "");
    const [place, setPlace] = useState(props.place || "");
    const [document, setDocument] = useState(props.document || "");
    const [type] = useState(props.type || "");

    const [error, setError] = useState({
        value: {validate: true, message: null},
        place: {validate: true, message: null},
        document: {validate: true, message: null}
    });

    useEffect(() => {
        changeInfo();
    }, [value]);

    useEffect(() => {
        changeInfo();
    }, [place]);

    useEffect(() => {
        changeInfo();
    }, [document]);

    const changeInfo = () => {
        props.changeInfo({
            Id: id,
            Value: value,
            Place: place,
            Document: document,
            Type: type
        }, props.index);
    };

    return (
        <>
            <div style={{fontSize: "12px", color: "#707070", marginTop: "20px"}}>Data de cadastro</div>
            <div style={{color: "#ed3136", fontSize: "12px", fontWeight: "bold", marginBottom: "5px"}}>{date}</div>
            <div style={{display: "grid", gridTemplateColumns: "25% 25% 35% 9% ", gridGap: "2%", marginBottom:"10px"}}>
                <div style={{display: "flex", padding: "0px 10px", background: "#f7f7f7", borderRadius: "5px"}}>
                    <ThemeProvider theme={Theme}>
                        <TextField
                            value={value}
                            onChange={(event) => {
                                let _valeu = maskMoney(event.target.value);
                                setValue(_valeu);
                            }}
                            onFocus={(event) => {
                                let _error = {...error};
                                _error.value.validate = true;
                                _error.value.message = "";
                                setError(_error);
                            }}
                            error={!error.value.validate}
                            helperText={error.value.message}
                            color="primary"
                            id="value"
                            name="value"
                            label="Valor (R$)"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Preencha o campo"
                            fullWidth
                        />
                    </ThemeProvider>
                </div>
                <div style={{display: "flex", padding: "0px 10px", background: "#f7f7f7", borderRadius: "5px"}}>
                    <ThemeProvider theme={Theme}>
                        <SelectApplication
                            label="Local"
                            value={place}
                            error={error.place}
                            onChange={setPlace}>
                            {props.options.map((item, index) => {
                                return <option value={item} key={index}>{item}</option>
                            })}

                            {/* <option value="Mercado">Mercado</option>
                            <option value="Farmácia">Farmácia</option>
                            <option value="Vestuário">Vestuário</option>
                            <option value="Combustível">Combustível</option>
                            <option value="Móveis">Móveis</option>
                            <option value="Eletrodomésticos">Eletrodomésticos</option>
                            <option value="Outros">Outros</option> */}
                        </SelectApplication>
                    </ThemeProvider>
                </div>
                <div style={{display: "flex", padding: "0px 10px", background: "#f7f7f7", borderRadius: "5px"}}>
                    <ThemeProvider theme={Theme}>
                        <TextField
                            value={document}
                            onChange={(event) => setDocument(event.target.value)}
                            onFocus={(event) => {
                                let _error = {...error};
                                _error.document.validate = true;
                                _error.document.message = "";
                                setError(_error);
                            }}
                            error={!error.document.validate}
                            helperText={error.document.message}
                            color="primary"
                            id="document"
                            name="document"
                            label="Número da nota"
                            size="small"
                            margin="dense"
                            type="text"
                            placeholder="Digite o número da nota"
                            fullWidth
                        />
                    </ThemeProvider>
                </div>
                <div style={{display: "flex", padding: "0px 10px", borderRadius: "5px", justifyContent: "center", alignItems: "center"}}>
                    <IconButton aria-label="delete" onClick={(event) => props.handleDelete(event, props.index)}>
                        <DeleteIcon style={{...Theme.palette.textColorRed}}/>
                    </IconButton>
                </div>
            </div>
        </>
    )
}

export default CardRegisterInvoice;