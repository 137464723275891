import { inArray, numberFormat } from "./Functions";

const onlyNumber = (value, key) => {
    let numerics = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    let _value = value + "" + key;
    _value = _value.split("").filter(item => inArray(item, numerics)).map(item => item);
    if (key === "Backspace") {
        let _delete = _value.pop();
        console.log("_delete", _delete);
    }
    _value = _value.join("");
    return _value;
}

export const maskCpf = (cpf, key) => {
    let _cpf = onlyNumber(cpf, key);
    _cpf = _cpf.split("");

    if (_cpf.length === 0) {
        _cpf = "";
    } else if (_cpf.length === 1) {
        _cpf = _cpf[0];
    } else if (_cpf.length === 2) {
        _cpf = _cpf[0] + "" + _cpf[1];
    } else if (_cpf.length === 3) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2];
    } else if (_cpf.length === 4) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3];
    } else if (_cpf.length === 5) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3] + "" + _cpf[4];
    } else if (_cpf.length === 6) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3] + "" + _cpf[4] + "" + _cpf[5];
    } else if (_cpf.length === 7) {
         _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3] + "" + _cpf[4] + "" + _cpf[5] + "." + _cpf[6];
    } else if (_cpf.length === 8) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3] + "" + _cpf[4] + "" + _cpf[5] + "." + _cpf[6] + "" + _cpf[7];
    } else if (_cpf.length === 9) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3] + "" + _cpf[4] + "" + _cpf[5] + "." + _cpf[6] + "" + _cpf[7] + "" + _cpf[8];
    } else if (_cpf.length === 10) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3] + "" + _cpf[4] + "" + _cpf[5] + "." + _cpf[6] + "" + _cpf[7] + "" + _cpf[8] + "-" + _cpf[9];
    } else if (_cpf.length === 11) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3] + "" + _cpf[4] + "" + _cpf[5] + "." + _cpf[6] + "" + _cpf[7] + "" + _cpf[8] + "-" + _cpf[9] + "" + _cpf[10];
    } else if (_cpf.length >= 11) {
        _cpf = _cpf[0] + "" + _cpf[1] + "" + _cpf[2] + "." + _cpf[3] + "" + _cpf[4] + "" + _cpf[5] + "." + _cpf[6] + "" + _cpf[7] + "" + _cpf[8] + "-" + _cpf[9] + "" + _cpf[10];
    }

    return _cpf;
}

export const maskMoney = (money, key) => {
    let strMoney = onlyNumber(money, key);
    if (strMoney.length <= 3) {
        strMoney = strMoney.toString().padStart(3,0)
    }

    let dezenaMilhar = strMoney.substring(0, (strMoney.length-2));
    let decimal = strMoney.substring(strMoney.length - 2)
    money = parseFloat(dezenaMilhar + "." + decimal);
    money = numberFormat(money, 2, ',', '.');
    return money;
}

export const maskFone = (fone, key) => {
    let _fone = onlyNumber(fone, key);

    if (_fone.length <= 10) {
        _fone = _fone.padEnd(10, '_');
        _fone = _fone.split("");
        let _partOne = _fone[0] + "" + _fone[1];
        let _partTwo = _fone[2] + _fone[3] + "" + _fone[4] + "" + _fone[5];
        let _partThree = _fone[6] + "" + _fone[7] + "" + _fone[8] + "" + _fone[9];
        _fone = "(" + _partOne + ")" + _partTwo + "-" + _partThree;
        fone = _fone;
    } else {
        _fone = _fone.padEnd(11, '_');
        _fone = _fone.split("");
        let _partOne = _fone[0] + "" + _fone[1];
        let _partTwo = _fone[2] + _fone[3] + "" + _fone[4] + "" + _fone[5] + "" + _fone[6];
        let _partThree = _fone[7] + "" + _fone[8] + "" + _fone[9] + "" + _fone[10];
        _fone = "(" + _partOne + ")" + _partTwo + "-" + _partThree;
        fone = _fone;
    }

    return fone;
}

export const maskDate = (date, key) => {
    let _date = onlyNumber(date, key);
    _date = _date.padEnd(8, '_');
    if (_date.length === 8) {
        _date = _date.split("");
        let _partOne = _date[0] + "" + _date[1];
        let _partTwo = _date[2] + "" + _date[3];
        let _partThree = _date[4] + "" + _date[5] + "" + _date[6] + "" + _date[7];
        date = _partOne + "/" + _partTwo + "/" + _partThree;
    }

    return date;
}

// export const maskZipCode = (zipCode, key) => {
//     let _zipCode = onlyNumber(zipCode, key);
//     _zipCode = _zipCode.padEnd(8, '_');
//     if (_zipCode.length === 8) {
//         _zipCode = _zipCode.split("");
//         let _partOne = _zipCode[0] + "" + _zipCode[1] + "" + _zipCode[2] + "" + _zipCode[3] + "" + _zipCode[4];
//         let _partTwo = _zipCode[5] + "" + _zipCode[6] + "" + _zipCode[7];
//         zipCode = _partOne + "-" + _partTwo;
//     }
//     return zipCode;
// }

export const maskZipCode = (zipCode) => {
    let _zipCode = onlyNumber(zipCode);
    if (_zipCode.length == 0) {
        _zipCode = "";
    } else if (_zipCode.length == 1) {
        _zipCode = `${_zipCode[0]}`;
    } else if (_zipCode.length == 2) {
        _zipCode = `${_zipCode[0]}${_zipCode[1]}`;
    } else if (_zipCode.length == 3) {
        _zipCode = `${_zipCode[0]}${_zipCode[1]}${_zipCode[2]}`;
    } else if (_zipCode.length == 4) {
        _zipCode = `${_zipCode[0]}${_zipCode[1]}${_zipCode[2]}${_zipCode[3]}`;
    } else if (_zipCode.length == 5) {
        _zipCode = `${_zipCode[0]}${_zipCode[1]}${_zipCode[2]}${_zipCode[3]}${_zipCode[4]}`;
    } else if (_zipCode.length == 6) {
        _zipCode = `${_zipCode[0]}${_zipCode[1]}${_zipCode[2]}${_zipCode[3]}${_zipCode[4]}-${_zipCode[5]}`;
    } else if (_zipCode.length == 7) {
        _zipCode = `${_zipCode[0]}${_zipCode[1]}${_zipCode[2]}${_zipCode[3]}${_zipCode[4]}-${_zipCode[5]}${_zipCode[6]}`;
    } else {
        _zipCode = `${_zipCode[0]}${_zipCode[1]}${_zipCode[2]}${_zipCode[3]}${_zipCode[4]}-${_zipCode[5]}${_zipCode[6]}${_zipCode[7]}`;
    }
    return _zipCode;
}

// export const maskRegularFone = (fone, key) => {
//     let _fone = onlyNumber(fone, key);
//     _fone = _fone.padEnd(10, '_');
//     _fone = _fone.split("");
//     let _partOne = _fone[0] + "" + _fone[1];
//     let _partTwo = _fone[2] + _fone[3] + "" + _fone[4] + "" + _fone[5];
//     let _partThree = _fone[6] + "" + _fone[7] + "" + _fone[8] + "" + _fone[9];
//     _fone = "(" + _partOne + ")" + _partTwo + "-" + _partThree;
//     fone = _fone;
//     return fone;
// }

export const maskRegularFone = (fone, key) => {
    let _fone = onlyNumber(fone);
    _fone = _fone.split("");
    if (_fone.length == 0) {
        _fone = "";
    } else if (_fone.length == 1) {
        _fone = `(${_fone[0]}`;
    } else if (_fone.length == 2) {
        _fone = `(${_fone[0]}${_fone[1]}`;
    } else if (_fone.length == 3) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}`;
    } else if (_fone.length == 4) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}`;
    } else if (_fone.length == 5) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}`;
    } else if (_fone.length == 6) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}`;
    } else if (_fone.length == 7) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}-${_fone[6]}`;
    } else if (_fone.length == 8) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}-${_fone[6]}${_fone[7]}`;
    } else if (_fone.length == 9) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}-${_fone[6]}${_fone[7]}${_fone[8]}`;
    } else {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}-${_fone[6]}${_fone[7]}${_fone[8]}${_fone[9]}`;
    }
    return _fone;
}

// export const maskCelular = (fone, key) => {
//     let _fone = onlyNumber(fone, key);
//     _fone = _fone.padEnd(11, '_');
//     _fone = _fone.split("");
//     let _partOne = _fone[0] + "" + _fone[1];
//     let _partTwo = _fone[2] + _fone[3] + "" + _fone[4] + "" + _fone[5] + "" + _fone[6];
//     let _partThree = _fone[7] + "" + _fone[8] + "" + _fone[9] + "" + _fone[10];
//     _fone = "(" + _partOne + ")" + _partTwo + "-" + _partThree;
//     fone = _fone;
//     return fone;
// }

export const maskCelular = (fone, key) => {
    let _fone = onlyNumber(fone);
    _fone = _fone.split("");
    if (_fone.length == 0) {
        _fone = "";
    } else if (_fone.length == 1) {
        _fone = `(${_fone[0]}`;
    } else if (_fone.length == 2) {
        _fone = `(${_fone[0]}${_fone[1]}`;
    } else if (_fone.length == 3) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}`;
    } else if (_fone.length == 4) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}`;
    } else if (_fone.length == 5) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}`;
    } else if (_fone.length == 6) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}`;
    } else if (_fone.length == 7) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}${_fone[6]}`;
    } else if (_fone.length == 8) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}${_fone[6]}-${_fone[7]}`;
    } else if (_fone.length == 9) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}${_fone[6]}-${_fone[7]}${_fone[8]}`;
    } else if (_fone.length == 10) {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}${_fone[6]}-${_fone[7]}${_fone[8]}${_fone[9]}`;
    } else {
        _fone = `(${_fone[0]}${_fone[1]})${_fone[2]}${_fone[3]}${_fone[4]}${_fone[5]}${_fone[6]}-${_fone[7]}${_fone[8]}${_fone[9]}${_fone[10]}`;
    }
    return _fone;
}