import React from "react";
import { Button } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import { useNavigate } from 'react-router-dom';

const StepOne = (props) => {

    let navigate = useNavigate();

    const handleWithTaxPayer = async (event) => {
        event.preventDefault();
        // _setMessageSuccess(false);
        // _setMessageError(false);
        // navigate(`/sistema`);
        props.setStep(1);
    }

    const handleWithoutTaxPayer = async (event) => {
        event.preventDefault();
        let hash = btoa(JSON.stringify({
            Register: true
        }));
        navigate(`/contribuinte/${hash}`);
    }

    return (
        <div style={{width: props.width, margin: "auto"}}>
            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleWithTaxPayer(event)}>
                    Contribuinte com cadastro
                </Button>
            </div>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonWhite, ...Theme.palette.spaceBottom}} onClick={(event) => handleWithoutTaxPayer(event)}>
                    Contribuinte sem cadastro
                </Button>
            </div>
        </div>
    );
}

export default StepOne;