import React, { useContext, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import logo from "../../images/nsr-premiada.png";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Theme from '../../components/views/Theme';
import CloseIcon from '@material-ui/icons/Close';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { useNavigate } from 'react-router-dom';
import { logout, setTokenFile } from '../../components/infrastructures/Auth';
import { useApplicationContext } from '../../components/infrastructures/Context';
import { RequestUserLoggedData, RequestUserLogout } from "../../components/infrastructures/UrlEndpoints";
import Http from "../../components/infrastructures/Http"

export default function Main(props) {

    let navigate = useNavigate();
    const [application, dispatch] = useApplicationContext();

    useEffect(() => {
        Http({
            method: "GET",
            url: RequestUserLoggedData(),
        }).then(response => {
            setTokenFile(response.data.token)
        }).catch(err => {
            logout();
            navigate("/");
        });
    }, []);

    const handleOpenMenu = (event) => {
        event.preventDefault();
        let menuWidth = "350px";
        if (window.innerWidth < 400) {
            menuWidth = "70%";
        }
        document.getElementById("mySidenav").style.width = menuWidth; //opens side navbar by 70 percent
    }

    const handleCloseMenu = (event) => {
        event.preventDefault();
        document.getElementById("mySidenav").style.width = "0";
    }

    const handleLink = (event, link) => {
        event.preventDefault();
        handleCloseMenu(event);
        navigate(link);
    }

    const handleLogout = (event) => {
        event.preventDefault();
        Http({
            method: "POST",
            url: RequestUserLogout(),
        }).then(response => {
            console.log("response", response);
            logout();
            navigate("/");
        }).catch(err => {
            logout();
            navigate("/");
        });
    }

    return (
        <div>
            <div id="mySidenav" className="sidenav-container">
                <span style={{...Theme.palette.closeMenu}}>
                    <IconButton aria-label="CloseIcon" onClick={(event) => handleCloseMenu(event)}>
                        <CloseIcon style={{...Theme.palette.textColorWhite}} />
                    </IconButton>
                </span>

                <div style={{...Theme.palette.mt20, ...Theme.palette.mb20}}>
                    <AccountCircleIcon style={{...Theme.palette.iconUserMenu}}/>
                    <div style={{...Theme.palette.nameUserMenu}}>{application.UserName}</div>
                </div>

                <List style={{...Theme.palette.listMenu}}>
                    <ListItem button onClick={(event) => handleLink(event, "/notas")}>
                        <ListItemIcon><ReceiptIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Cadastrar notas" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/usuario")}>
                        <ListItemIcon><PersonAddIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Cadastrar usuário" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/usuarios")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Listar usuários" />
                    </ListItem>

                    <ListItem button onClick={(event) => handleLink(event, "/contribuinte")}>
                        <ListItemIcon><PersonAddIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Cadastrar contribuinte" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/contribuintes")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Listar contribuintes" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/notas-contribuintes")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Listar contribuintes com notas trocadas" />
                    </ListItem>

                    <ListItem button onClick={(event) => handleLink(event, "/contribuintes-notas-enviadas")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Listar contribuintes com login no sistema" />
                    </ListItem>

                    <ListItem button onClick={(event) => handleLink(event, "/notas-em-analise")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Analisar notas" />
                    </ListItem>

                    <ListItem button onClick={(event) => handleLink(event, "/listar-certificados")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Listar Certificados" />
                    </ListItem>

                    {/* <ListItem button onClick={(event) => handleLink(event, "/cancelar-certificados")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Cancelar certificados" />
                    </ListItem> */}

                    <ListItem button onClick={(event) => handleLink(event, "/cadastrar-sorteio")}>
                        <ListItemIcon><CardGiftcardIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Cadastrar sorteio" />
                    </ListItem>

                    <ListItem button onClick={(event) => handleLink(event, "/listar-sorteios")}>
                        <ListItemIcon><FormatListBulletedIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Listar sorteios" />
                    </ListItem>

                    <ListItem button onClick={(event) => handleLink(event, "/sortear")}>
                        <ListItemIcon><CardGiftcardIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Sortear" />
                    </ListItem>

                    {/*

                    <ListItem button onClick={(event) => handleLink(event, "/menus-dados")}>
                        <ListItemIcon><SettingsIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Meus dados" />
                    </ListItem>
                    <ListItem button onClick={(event) => handleLink(event, "/alterar-senha")}>
                        <ListItemIcon><LockOpenIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Alterar minha senha" />
                    </ListItem> */}
                    <ListItem button onClick={(event) => handleLogout(event)}>
                        <ListItemIcon><ExitToAppIcon style={{...Theme.palette.textColorWhite}} /></ListItemIcon>
                        <ListItemText primary="Sair" />
                    </ListItem>
                </List>
            </div>

            <nav style={{height: "100px", zIndex: 9}}>
                <IconButton aria-label="menu" onClick={(event) => handleOpenMenu(event)}>
                    <MenuIcon style={{...Theme.palette.textColorWhite}}/>
                </IconButton>
                <img src={logo} style={{height: "90px", marginLeft: "20px", marginRight: "20px"}} alt="Nova Santa Rita Premiada" />
                <Button style={{...Theme.palette.buttonRegisterInvoice}} onClick={(event) => handleLink(event, "/notas")}>
                    Cadastrar notas
                </Button>
                <Button style={{...Theme.palette.buttonRegisterInvoice, marginLeft: "20px"}} onClick={(event) => handleLink(event, "/notas-em-analise")}>
                    Analisar notas
                </Button>
            </nav>

            <div style={{...Theme.palette.bodySystem}}>
                {props.children}
            </div>
        </div>
    );
}