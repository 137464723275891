import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import Theme from '../../../components/views/Theme';
import { ThemeProvider } from '@material-ui/styles';
import { validateDate, getDateFormat } from '../../../components/views/Functions';
import { KeyboardDatePicker } from '@material-ui/pickers';

const StepOne = (props) => {

    const [awardDate, setAwardDate] = React.useState(null);

    const [error, setError] = useState({
        awardDate: {validate: true, message: null},
    });

    const validateForm = () => {
        let _error = {...error};
        let _verify = false;

        if (awardDate === null || awardDate.toString() === "Invalid Date") {
            _error.awardDate.validate = false;
            _error.awardDate.message = "Por favor, insira a data do sorteio. Campo é obrigatório.";
            _verify = true;
        } else if (!validateDate(getDateFormat(awardDate))) {
            _error.awardDate.validate = false;
            _error.awardDate.message = "Campo data de nascimento inválido.";
            _verify = true;
        }

        setError(_error);
        return _verify;
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();

        if (validateForm()) return;

        props.setPreviewData({
            AwardDate: awardDate
        });

        props.setStep(1);
    }

    const handleDateChange = (date) => {
        setAwardDate(date);
    };

    return (
        <div key={props.keyData} style={{width: props.width, margin: "auto", marginTop: "40px"}}>
            <ThemeProvider theme={Theme}>

                <KeyboardDatePicker
                    disableToolbar
                    color="primary"
                    format="dd/MM/yyyy"
                    margin="dense"
                    id="date-picker-inline"
                    label="Data do sorteio"
                    placeholder="Digite a data do sorteio"
                    error={!error.awardDate.validate}
                    helperText={error.awardDate.message}
                    value={awardDate}
                    onChange={handleDateChange}
                    onFocus={(event) => {
                        let _error = {...error};
                        _error.awardDate.validate = true;
                        _error.awardDate.message = "";
                        setError(_error);
                    }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    fullWidth
                />
            </ThemeProvider>

            <div style={{...Theme.palette.divCenter}}>
                <Button style={{...Theme.palette.buttonRed, ...Theme.palette.mt20}} onClick={handleSubmitForm}>
                    Próximo
                </Button>
            </div>
        </div>
    );
}

export default StepOne;
