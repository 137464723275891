// import React from 'react';
// // import {
// //     Chart as ChartJS,
// //     CategoryScale,
// //     LinearScale,
// //     BarElement,
// //     Title,
// //     Tooltip,
// //     Legend,
// // } from 'chart.js';
// // import { Doughnut } from 'react-chartjs-2';

// // ChartJS.register(
// //     CategoryScale,
// //     LinearScale,
// //     BarElement,
// //     Title,
// //     Tooltip,
// //     Legend
// // );

// // export const options = {
// //     responsive: true,
// //     plugins: {
// //         legend: {
// //             position: 'top'
// //         },
// //         title: {
// //             display: true,
// //             text: 'Valores totais por trocas',
// //         },
// //     },
// // };

// const GraficDoughnut = ({data}) => {
//     return <div>GraficDoughnut</div>
// }

// export default GraficDoughnut;

////////////////////////
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Title, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: 'Valores totais por trocas',
        },
    },
};


const GraficDoughnut = ({data}) => {
    return <Doughnut options={options} data={data} />;
}

export default GraficDoughnut;