import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    indexAxis: 'y' ,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'right',
        },
        title: {
            display: true,
            text: 'Acesso por dispositivos',
        },
    },
};

const labels = ['Acessos'];


const GraficBarHorizontal = ({android, iphone, web}) => {
    const androidValue = android || 0;
    const iphoneValue = iphone || 0;
    const webValue = web || 0;
    const data = {
        labels,
        datasets: [
            {
                label: 'Android',
                data: [androidValue],
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Iphone',
                data: [iphoneValue],
                borderColor: 'rgba(53, 162, 235, 1)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Web',
                data: [webValue],
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
        ],
    };

    return <Bar options={options} data={data} />;
}

export default GraficBarHorizontal;
