import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Typography, Button } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import StickyHeadTable from "../../components/views/StickyHeadTable";
import { formatDateBR } from "../../components/views/Functions";
import AlertModal from "../../components/views/AlertModal";
import { RequestPrizeAwards, RequestCouponsPrize, RequestConfirmWinner } from "../../components/infrastructures/UrlEndpoints";

const Prize = () => {

    const { hash } = useParams();
    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [awards, setAwards] = useState([]);
    const [date, setDate] = useState(null);
    const [letter, setLetter] = useState("");
    const [nbOne, setNbOne] = useState("");
    const [nbTwo, setNbTwo] = useState("");
    const [nbThree, setNbThree] = useState("");
    const [nbFour, setNbFour] = useState("");
    const [nbFive, setNbFive] = useState("");
    const [winner, setWinner] = useState({});
    const [viewContact, setViewContact] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState([]);

    useEffect(() => {
        async function processResquetAPI() {
            try {
                let _params = JSON.parse(atob(hash));
                if (typeof _params.Date !== "undefined") {
                    await getAwards(_params.Date);
                    setDate(formatDateBR(_params.Date));
                }
            } catch (error) {
                const data = new Date();
                let dd = data.getDate().toString().padStart(2, "0");
                let mm = (data.getMonth()+1).toString().padStart(2, "0");
                let yy = data.getFullYear();
                let date = `${yy}-${mm}-${dd}`;
                await getAwards(date);
                setDate(formatDateBR(date));
            }
        }

        processResquetAPI()
    }, [hash]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getAwards = async (date) => {
        await Http({
            method: "GET",
            url: RequestPrizeAwards(date),
        }).then(response => {
            let _awards = response.data.map(item => {
                console.log("item", item);
                return {
                    Id: item.Id,
                    Award: item.Description,
                    Special: item.Special,
                    Winner: (typeof item.Winner !== "undefined") ? item.Winner.Nome : null
                }
            })
            setAwards(_awards);
        }).catch(err => {
            setAwards([]);
        });
    }

    const handleCleanPrize = (event) => {
        event.preventDefault();
        setLetter("");
        setNbOne("");
        setNbTwo("");
        setNbThree("");
        setNbFour("");
        setNbFive("");
        handleCancelCouponPrize(event);
    }

    const handleGetCouponsPrize = (event) => {
        event.preventDefault();

        let _awards = [...awards];
        let _award = _awards.filter(item => item.Winner === null).map(item => item).shift();
        let _coupon = `${letter || "A"}${nbOne || 0}${nbTwo || 0}${nbThree || 0}${nbFour || 0}${nbFive || 0}`;

        if (typeof _award == "undefined") {
            setMessage("Não há mais prêmios a serem sorteados");
            setOpen(true);
            return;
        }

        _setLoading(true);

        Http({
            method: "POST",
            url: RequestCouponsPrize(),
            data: {
                Coupon: _coupon.toUpperCase(),
                Special: _award.Special
            }
        }).then(response => {
            _setLoading(false);
            if (response.data.Status === "true") {
                let taxPayer = response.data.TaxPayer;
                let _winner = {
                    Name: taxPayer.Nome,
                    Address: `${taxPayer.TipoLogradouro} ${taxPayer.Endereco} - nº ${taxPayer.Numero}`,
                    District: taxPayer.Bairro,
                    City: taxPayer.Cidade,
                    Zipcode: taxPayer.Cep,
                    Celular: taxPayer.Celular,
                    Fone: taxPayer.Telefone,
                    CouponId: response.data.CouponId,
                    Award: _award
                }
                setWinner(_winner);
                setViewContact(false);
            } else {
                setMessage(response.data.Message);
                setOpen(true);
            }
        }).catch(err => {
            _setLoading(false);
            setMessage(err.response.data.Message);
            setOpen(true);
        });
    }

    const handleSetViewContant = (event) => {
        event.preventDefault();
        setViewContact(!viewContact)
    }

    const handleCancelCouponPrize = (event) => {
        event.preventDefault();
        setViewContact(false);
        setWinner({});
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSaveCouponPrize = (event) => {
        event.preventDefault();
        _setLoading(true);

        Http({
            method: "POST",
            url: RequestConfirmWinner(),
            data: {
                AwardId: winner.Award.Id,
                CouponId: winner.CouponId
            }
        }).then(response => {
            _setLoading(false);
            let _awards = [...awards];
            _awards = _awards.map(item => {
                if(item.Id === winner.Award.Id) {
                    item.Winner = winner.Name;
                }
                return item;
            });
            setAwards(_awards);
            handleCleanPrize(event);
        }).catch(err => {
            _setLoading(false);
            setMessage("Falha inesperada. Entre em contato com o Administrador [SC.008]");
            setOpen(true);
        });
    }

    const columns = [
        {
            id: 'Award',
            label: 'Prêmios',
            align: 'left'
        },
        {
            id: 'Winner',
            label: 'Ganhador',
            align: 'left'
        }
    ];

    return (
        <div style={{width: "800px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <AlertModal
                open={open}
                handleClose={handleClose}
                message={message}
                title="Mensagem"
                key={open}
            />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Sorteio</Typography>
            </div>

            <div style={{padding: "20px 0px"}}>
                <span style={{fontSize: "14px", color: "#ed3136"}}>Data sorteio:</span>
                <span style={{fontSize: "18px", fontWeight: "bold", color: "gray", marginLeft: "10px"}}>{date}</span>
            </div>


            <div style={{display: "grid", gridTemplateColumns: "6% 6% 6% 6% 6% 6% 29% 28%", gridGap: "1%"}}>
                <div>
                    <input
                        type="text"
                        value={letter}
                        onChange={(event) => setLetter(event.target.value)}
                        style={{
                            border:"1px solid gray",
                            padding: "19px 0px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontWeight: "bold",
                            textTransform: "uppercase"
                        }}
                        maxLength="1" />
                </div>

                <div>
                    <input
                        type="text"
                        value={nbOne}
                        onChange={(event) => setNbOne(event.target.value)}
                        style={{
                            border:"1px solid gray",
                            padding: "19px 0px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontWeight: "bold",
                            textTransform: "uppercase"
                        }}
                        maxLength="1" />
                </div>
                <div>
                    <input
                        type="text"
                        value={nbTwo}
                        onChange={(event) => setNbTwo(event.target.value)}
                        style={{
                            border:"1px solid gray",
                            padding: "19px 0px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontWeight: "bold",
                            textTransform: "uppercase"
                        }}
                        maxLength="1" />
                </div>
                <div>
                    <input
                        type="text"
                        value={nbThree}
                        onChange={(event) => setNbThree(event.target.value)}
                        style={{
                            border:"1px solid gray",
                            padding: "19px 0px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontWeight: "bold",
                            textTransform: "uppercase"
                        }}
                        maxLength="1" />
                </div>
                <div>
                    <input
                        type="text"
                        value={nbFour}
                        onChange={(event) => setNbFour(event.target.value)}
                        style={{
                            border:"1px solid gray",
                            padding: "19px 0px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontWeight: "bold",
                            textTransform: "uppercase"
                        }}
                        maxLength="1" />
                </div>

                <div>
                    <input
                        type="text"
                        value={nbFive}
                        onChange={(event) => setNbFive(event.target.value)}
                        style={{
                            border:"1px solid gray",
                            padding: "19px 0px",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "15px",
                            fontWeight: "bold",
                            textTransform: "uppercase"
                        }}
                        maxLength="1" />
                </div>
                <div>
                    <Button style={{...Theme.palette.buttonRed, width: "100%"}} onClick={handleGetCouponsPrize}>
                        Verificar
                    </Button>
                </div>
                <div>
                    <Button style={{...Theme.palette.buttonWhite, width: "100%"}} onClick={handleCleanPrize}>
                        Limpar
                    </Button>
                </div>
            </div>

            {JSON.stringify(winner) !== "{}" && (
                <div style={{marginTop: "20px"}}>
                    <p>
                        <span style={{fontSize: "14px", fontWeight: "bold", color: "gray", marginLeft: "0px"}}>Nome:</span>
                        <span style={{fontSize: "14px", fontWeight: "bold", color: "#333333", marginLeft: "10px"}}>{winner.Name}</span>
                        <a style={{fontSize: "14px", color: "#ed3136", marginLeft: "10px", cursor: "pointer", textDecoration: "underline", fontStyle: "italic"}} onClick={handleSetViewContant}>{viewContact ? "Esconder contato" : "Visualizar contato"}</a>
                    </p>



                    {viewContact && (
                        <>
                            <p>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "gray", marginLeft: "0px"}}>Endereço:</span>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "#333333", marginLeft: "10px"}}>{winner.Address}</span>
                            </p>
                            <p>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "gray", marginLeft: "0px"}}>Bairro:</span>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "#333333", marginLeft: "10px"}}>{winner.District}</span>
                            </p>
                            <p>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "gray", marginLeft: "0px"}}>Cidade:</span>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "#333333", marginLeft: "10px"}}>{winner.City}</span>
                            </p>
                            <p>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "gray", marginLeft: "0px"}}>CEP:</span>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "#333333", marginLeft: "10px"}}>{winner.Zipcode}</span>
                            </p>
                            <p>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "gray", marginLeft: "0px"}}>Fone:</span>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "#333333", marginLeft: "10px"}}>{winner.Fone}</span>
                            </p>
                            <p>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "gray", marginLeft: "0px"}}>Celular:</span>
                                <span style={{fontSize: "14px", fontWeight: "bold", color: "#333333", marginLeft: "10px"}}>{winner.Celular}</span>
                            </p>
                        </>
                    )}
                    <div style={{display: "grid", gridTemplateColumns: "49% 49%", gridGap: "2%"}}>
                        <div>
                            <Button style={{...Theme.palette.buttonRed, width: "100%"}} onClick={handleSaveCouponPrize}>
                                Confirmar
                            </Button>
                        </div>
                        <div>
                            <Button style={{...Theme.palette.buttonWhite, width: "100%"}} onClick={handleCancelCouponPrize}>
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            <div style={{marginTop: "20px"}}>
                <StickyHeadTable columns={columns} rows={awards}/>
            </div>
        </div>

    );
};

export default Prize;