import React, { useState, useEffect } from "react";
import Http from "../../../components/infrastructures/Http"
import PageLoad from "../../../components/views/PageLoad";
import Theme from '../../../components/views/Theme';
import Certificates from "../../../components/views/Certificates";
import { Button } from '@material-ui/core';
import AlertModal from "../../../components/views/AlertModal";
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@material-ui/icons/Warning';
import { RequestCouponsByGroupInvoiceUUID, RequestPrintCouponsByGroupInvoiceUUID, RequestDownload } from "../../../components/infrastructures/UrlEndpoints";

const StepFour = (props) => {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [coupons, setCoupons] = useState([]);

    const [open, setOpen] = useState(false);
    const [message] = useState([
        "Fallha ao processar arquivo PDF para impressão",
        "Entre em contato com o administrador"
    ]);

    let groupInvoiceUUID = props.groupInvoiceUUID;

    useEffect(() => {
        if (groupInvoiceUUID === null || groupInvoiceUUID === "") return;

        async function processResquetAPI() {
            _setLoading(true);
            await getCoupons(groupInvoiceUUID);
            _setLoading(false);
        }

        processResquetAPI()

    }, [groupInvoiceUUID]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getCoupons = async (groupInvoiceUUID) => {
        _setLoading(true);
        await Http({
            method: "GET",
            url: RequestCouponsByGroupInvoiceUUID(groupInvoiceUUID)
        }).then(response => {
            _setLoading(false);
            setCoupons(response.data.map(item => item.Certificate))
        }).catch(err => {
            _setLoading(false);
            setCoupons([]);
        });
    }

    const handlePrintCertificates = (event) => {
        event.preventDefault();
        createPdf();
    }

    const createPdf = () => {
        _setLoading(true);
        Http({
            method: "GET",
            url: RequestPrintCouponsByGroupInvoiceUUID(groupInvoiceUUID)
        }).then(response => {
            _setLoading(false);
            download(response.data);
        }).catch(err => {
            _setLoading(false);
            setOpen(true);
        });
    }

    const download = (file) => {
        _setLoading(true);
        Http({
            method: "GET",
            url: RequestDownload(file),
            responseType: 'blob'
        }).then(response => {
            _setLoading(false);
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('download', 'file.pdf');
            link.href = url;
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            _setLoading(false);
            setOpen(true);
        });
    }

    // const downloadFile = (fileName) => {
    //     var url = Http.getPrintUrl() + "/files/pdf/" + fileName;
    //     var link = document.createElement('a');
    //     link.setAttribute('target', '_blank');
    //     link.href = url;
    //     link.setAttribute('download', 'file.pdf'); //or any other extension
    //     document.body.appendChild(link);
    //     link.click();
    // }

    const handleClose = (event) => {
        setOpen(false);
    }

    const handleResetInformations = (event) => {
        event.preventDefault();
        try {
            props.resetInformations();
        } catch (error) {
            console.warn(error);
        }

        navigate("/notas");
    }

    const handleLink = (event, link) => {
        event.preventDefault();
        navigate(link);
    }

    return (
        <div style={{width: "430px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <AlertModal
                open={open}
                handleClose={handleClose}
                message={message}
                title="Atenção"
                key={open}
            />

            <div style={{display: (coupons.length !== 0) ? "block" : "none"}}>
                <div style={{width: "430px", display: "grid", gridGap: "4%", gridTemplateColumns: "48% 48%"}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom, width: "100%"}} onClick={(event) => handlePrintCertificates(event)}>
                        Imprimir certificados
                    </Button>

                    <Button style={{...Theme.palette.buttonWhite, ...Theme.palette.spaceBottom, width: "100%"}} onClick={(event) => handleResetInformations(event)}>
                        Cadastrar novas notas
                    </Button>
                </div>

                <div style={{width: "430px", display: "grid", gridGap: "10px", gridTemplateColumns: "repeat(4, 100px)"}}>
                    {coupons.map((item, index) => {
                        return <Certificates coupon={item} key={index} />
                    })}
                </div>
            </div>

            <div style={{display: (coupons.length === 0) ? "block" : "none"}}>
                <div style={{textAlign: "center"}}>
                    <WarningIcon style={{...Theme.palette.textColorRed, ...Theme.palette.textSize50}} />
                </div>

                <div>
                    <p style={{...Theme.palette.textModalRegisterSuccessPrimary}}>Não há certificados para imprimir</p>
                </div>

                <br />

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleLink(event, "/notas")}>
                        Analisar notas
                    </Button>
                </div>

                <div style={{...Theme.palette.divCenter, ...Theme.palette.mt20}}>
                    <Button style={{...Theme.palette.buttonRed, ...Theme.palette.spaceBottom}} onClick={(event) => handleResetInformations(event)}>
                        Cadastrar notas notas
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default StepFour;