import React, { useState, useEffect } from "react";
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import { Typography } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import GraficBar from "../../components/views/GraficBar";
import GraficDoughnut from "../../components/views/GraficDoughnut";
import GraficBarHorizontal from "../../components/views/GraficBarHorizontal";
import { RequestDashboard } from "../../components/infrastructures/UrlEndpoints";

const Dashboard = () => {

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [productData, setProductData] = useState([]);
    const [serviceData, setServiceData] = useState([]);

    const [androidData, setAndroidData] = useState(0);
    const [iphoneData, setIphoneData] = useState(0);
    const [webData, setWebData] = useState(0);

    const [doughnutTotalValues, setDoughnutTotalValues] = useState({
        labels: ['Produtos', 'Serviços'],
        datasets: [
            {
                label: '# dos Valores',
                data: [0, 0],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)'
                ],
                borderWidth: 1,
            },
        ],
    });

    useEffect(() => {
        console.log("aqui", doughnutTotalValues);
    },[doughnutTotalValues]);

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getDashboard();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getDashboard = async () => {
        await Http({
            method: "GET",
            url: RequestDashboard(),
        }).then(response => {
            response.data.amountLoginInApp.map(item => {
                if (item.Application == 'android') setAndroidData(item.amount);
                if (item.Application == 'ios') setIphoneData(item.amount);
                if (item.Application == 'web') setWebData(item.amount);
            })

            let serviceValueByMouth = response.data.amountAndValueInvoicesMouth.service.map(item => {
                let value = 0
                if (item.length > 0) {
                    value = parseFloat(item.map(mouth => mouth.value).shift());
                }
                return value;
            });

            let productValueByMouth = response.data.amountAndValueInvoicesMouth.product.map(item => {
                let value = 0
                if (item.length > 0) {
                    value = parseFloat(item.map(mouth => mouth.value).shift());
                }
                return value;
            });

            setProductData(productValueByMouth);
            setServiceData(serviceValueByMouth);

            let serviceValue = response.data.amountAndValueInvoice[0].value;
            let productValue = response.data.amountAndValueInvoice[1].value;
            setDoughnutTotalValues({
                labels: ['Produtos', 'Serviços'],
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: 'Valores totais por trocas'
                        }
                    }
                },
                datasets: [{
                    label: '# dos Valores',
                    data: [productValue, serviceValue],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)'
                    ],
                    borderWidth: 1
                }],
            })


        }).catch(err => {
            console.log("err", err);
        });
    }

    return (
        <div style={{width: "800px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Dashboard</Typography>
            </div>

            <div style={{display: "grid", gridTemplateColumns: "33% 66%", gridGap: "1%"}}>
                <div style={{boxShadow: "0 0 10px #dfdfdf", padding:"15px", borderRadius: "10px"}}>
                    <GraficDoughnut data={doughnutTotalValues} />
                </div>
                <div style={{boxShadow: "0 0 10px #dfdfdf", padding:"15px", borderRadius: "10px"}}>
                    <GraficBar product={productData} service={serviceData} />
                </div>
            </div>

            <div style={{display: "grid", gridTemplateColumns: "66% 33%", gridGap: "1%"}}>
                <div style={{boxShadow: "0 0 10px #dfdfdf", padding:"15px", borderRadius: "10px"}}>
                    <GraficBarHorizontal android={androidData} iphone={iphoneData} web={webData} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;