import React, { useState, useEffect } from "react";
import { Typography } from '@material-ui/core';
import Theme from '../../components/views/Theme';
import { useNavigate } from 'react-router-dom';
import Http from "../../components/infrastructures/Http"
import PageLoad from "../../components/views/PageLoad";
import StickyHeadTable from "../../components/views/StickyHeadTable";
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageIcon from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { getTypeFile, encode, numberToMoney, formatDateHoraMinBR, formatCpfView } from "../../components/views/Functions";
import { RequestInvoicesStatus } from "../../components/infrastructures/UrlEndpoints";

const ListInvoicesInAnalysis = () => {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [keyLoading, setKeyLoading] = useState(Math.random());
    const [list, setList] = useState([]);

    useEffect(() => {
        async function processResquetAPI() {
            _setLoading(true);
            await getInvoicesInAnalysis();
            _setLoading(false);
        }

        processResquetAPI()
    },[]);

    const _setLoading = (option) => {
        setLoading(option);
        setKeyLoading(Math.random());
    }

    const getInvoicesInAnalysis = async () => {
        await Http({
            method: "GET",
            url: RequestInvoicesStatus("?status=EM_ANALISE")
        }).then(response => {
            let _list = response.data.map(item => {
                item.Cpf = formatCpfView(item.TaxPayerCpf);
                return {
                    Option: getButtons(item),
                    FileType: getIcon(getTypeFile(item.FileName)),
                    DataHora: formatDateHoraMinBR(item.Data),
                    Value: numberToMoney(parseFloat(item.Value)),
                    Document: item.Document,
                    TaxPayerName: item.TaxPayerName,
                    Status: "Em análise"
                }
            })
            setList(_list);
        }).catch(err => {
            setList([]);
        });
    }

    const handleAnalysis = (event, item) => {
        console.log("item", item);
        event.preventDefault();
        let hash = encode(JSON.stringify(item));
        navigate(`/nota-em-analise/${hash}`);
    }


    const getIcon = (option) => {
        if (option === "PDF") {
            return <PictureAsPdfIcon style={{...Theme.palette.textColorRed}}/>;
        } else {
            return <ImageIcon style={{...Theme.palette.textColorRed}}/>
        }
    }

    const getButtons = (item) => {
        return (
            <>
                <IconButton aria-label="delete" onClick={(event) => handleAnalysis(event, item)}>
                    <VisibilityIcon style={{...Theme.palette.textColorRed}}/>
                </IconButton>
            </>
        );
    }

    const columns = [
        {
            id: 'Option',
            label: 'Opções'
        },
        {
            id: 'FileType',
            label: 'Arquivo',
            align: 'center'
        },
        {
            id: 'DataHora',
            label: 'Data de envio',
            align: 'left'
        },
        {
          id: 'Value',
          label: 'Valor da nota',
          align: 'right'
        },
        {
            id: 'Document',
            label: 'Número da nota',
            align: 'left'
        },
        {
            id: 'TaxPayerName',
            label: 'Contribuinte',
            align: 'left'
        },
        {
            id: 'Status',
            label: 'Status',
            align: 'center'
        }
    ];

    return (
        <div style={{width: "900px", margin: "auto"}}>
            <PageLoad open={loading} key={keyLoading} />

            <div style={{margin:"auto", paddingTop: "20px"}}>
                <Typography variant="h6" component="h1" align="left" style={Theme.palette.textColorRed}>Notas em análise</Typography>
            </div>

            <div>
                <StickyHeadTable columns={columns} rows={list}/>
            </div>
        </div>
    );
};

export default ListInvoicesInAnalysis;